<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">Unenroll Patient</h5>
        <ion-button slot="end" mode="ios" @click="closeModal" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ErrorNotification :errors="errors" />
      <form @submit.prevent="checkForm" novalidate="true" id="initializationForm">
        <h6>Please provide a reason for unenrolling this patient.</h6>
        <p>Note: once a patient has been unenrolled, they will have to go through the enrollment process to re-enroll.</p>
        <textarea v-model="reason" id="reason" class="form-control" />
      </form>
    </ion-content>
    <ion-footer>
      <button class="prime-button button-block margin-bottom-ten" :class="{ 'button-pending': isLoading }" form="initializationForm" type="submit">
        <span>Unenroll</span>
        <ion-spinner name="dots" duration="1200" />
      </button>
      <button class="prime-button button-block button-secondary" @click="closeModal" type="button">
        Cancel
      </button>
    </ion-footer>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import ErrorNotification from "@/components/Global/ErrorNotification";
import { EventBus } from "@/services/Events.js";

export default {
  name: "UnenrollForm",
  components: {
    ErrorNotification
  },
  props: {
    enrollment: Object
  },
  data() {
    return {
      isLoading: false,
      beneficiaryId: 0,
      errors: [],
      reason: ""
    };
  },
  methods: {
    async submit() {
      this.isLoading = true;
      const path = document.config.enrollmentApi + "update/" + this.enrollment.id;
      const method = "PUT";
      const payload = Object.assign({}, this.enrollment, {
        note: this.reason,
        status: "unenrolled"
      });

      try {
        await httpSend({ path, method, authToken: true, payload });
        this.isLoading = false;
        this.$ionic.modalController.dismiss();
        this.$ionic.toastController
          .create({
            header: "Unenrollment Processed",
            message: "",
            duration: 4000,
            position: "top",
            color: "primary",
            mode: "ios"
          })
          .then(m => m.present());
        EventBus.$emit("reloadRosterView");
      } catch (err) {
        this.isLoading = false;
        this.errors.push(err.response.data.message);
      }
    },
    checkForm() {
      this.errors = [];
      if (!this.reason) {
        this.errors.push("Please provide a reason.");
      }
      if (!this.errors.length) {
        this.submit();
      }
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
.prime-notification-panel {
  background: var(--color-secondary-button);
  border-radius: 10px;
  padding: 20px;
  margin: 0 0 20px 0;
  display: flex;
  font-weight: 400;
}
.prime-notification-panel svg {
  flex-shrink: 0;
  margin-right: 10px;
}
ion-footer {
  padding: 20px;
}
</style>
