<template>
  <span></span>
</template>

<script>
export default {
  name: "AdvancedFilter",
  data() {
    return {
      model: null
    }
  },
  methods: {
    updateFilter() {
      this.params.filterChangedCallback();
    },

    doesFilterPass() {
      return true;
    },

    isFilterActive() {
      return false;
    },

    getModel() {
      return this.model;
    },

    setModel(model) {
      this.model = model;
    }
  }
};
</script>

<style scoped></style>
