<template>
  <span>{{ params.value }}</span>
</template>

<script>
export default {
  name: "SimpleTextRenderer",
  computed: {}
};
</script>

<style scoped></style>
