<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">Send Enrollment Link</h5>
        <ion-button slot="end" mode="ios" @click="closeModal" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="errors.length > 0" class="error-notification">
        <IconWarning />
        <ion-text v-for="error in errors" :key="error">{{ error }}</ion-text>
      </div>
      <form @submit.prevent="checkForm" novalidate="true" id="initializationForm">
        <div class="prime-notification-panel">
          <IconChatBubble class="icon-small" />
          <ion-text>Enter the patient's phone number below (must support SMS) to send them a link to the LumerisEngage enrollment form.</ion-text>
        </div>
        <h6>Patient's phone number</h6>
        <input v-model="sms" id="sms" type="text" class="form-control margin-bottom-twenty" placeholder="123-456-7890" name="sms" />
        <button class="prime-button button-block" :class="{ 'button-pending': isLoading }" type="submit">
          <span>Send Link</span>
          <ion-spinner name="dots" duration="1200" />
        </button>
      </form>
    </ion-content>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import isValidPhoneNumber from "@/utils/FormHelpers/isValidPhoneNumber";
import formatPhoneNumber from "@/utils/FormHelpers/formatPhoneNumber";
import IconWarning from "@/components/Global/Icons/IconWarning";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";

export default {
  name: "EnrollmentInitializationForm",
  data() {
    return {
      isLoading: false,
      beneficiaryId: 0,
      sms: "",
      errors: []
    };
  },
  components: {
    IconWarning,
    IconChatBubble
  },
  methods: {
    async submit() {
      this.isLoading = true;
      const path = document.config.enrollmentApi + "initialize/" + this.beneficiaryId;
      const method = "POST";
      const payload = new FormData();
      payload.set("sms", formatPhoneNumber(this.sms));

      try {
        await httpSend({ path, method, authToken: true, payload }).then(result => {
          if (result.data.status == "400") {
            this.$ionic.toastController
              .create({
                header: "ERROR: Could not enroll this patient",
                message: result.data.message,
                duration: 10000,
                position: "top"
              })
              .then(m => m.present());
          }
        });
        this.isLoading = false;
        this.$ionic.modalController.dismiss();
      } catch (err) {
        this.isLoading = false;
        this.errors.push(err.response.data.message);
      }
    },
    checkForm() {
      this.errors = [];
      if (!this.sms) {
        this.errors.push("SMS number required.");
      } else if (!isValidPhoneNumber(this.sms)) {
        this.errors.push("Valid phone number required.");
      }
      if (!this.errors.length) {
        this.submit();
      }
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    }
  }
};
</script>

<style scoped>
.prime-notification-panel {
  background: var(--color-secondary-button);
  border-radius: 10px;
  padding: 20px;
  margin: 0 0 20px 0;
  display: flex;
  font-weight: 400;
}
.prime-notification-panel svg {
  flex-shrink: 0;
  margin-right: 10px;
}
</style>
