<template>
  <span v-if="!isGrouped" v-tooltip.top="tooltip" class="duration">{{ duration }}</span>
</template>

<script>
import moment from "moment";

export default {
  name: "DurationRenderer",
  computed: {
    isGrouped() {
      return !this.params.data.hasOwnProperty("accountid");
    },
    duration() {
      const value = this.params.value;
      if (value == null) {
        return "Never";
      } else {
        const duration = moment.duration(moment(value).diff(moment()));
        return duration.humanize(true);
      }
    },
    tooltip() {
      return this.params.value != null ? moment(this.params.value).format("h:mmA - M/DD/YYYY") : "Patient has not engaged yet";
    }
  }
};
</script>

<style scoped>
.duration {
  text-transform: capitalize;
}
</style>
