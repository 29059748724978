<template>
  <div class="prime-modal column-stretch-wrap full-height">
    <div class="column-stretch-header">
      <ion-header>
        <ion-toolbar>
          <h5 class="text-bold margin-top-ten margin-bottom-ten">Save Current Column/Filter Set</h5>
          <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
        </ion-toolbar>
      </ion-header>
    </div>
    <div class="column-stretch-body bottom-divider">
      <ion-row v-if="errMsgs.length" class="pad-lr-twenty">
        <ion-col class="pad-ten">
          <ul class="danger-well notifications-well">
            <li v-for="(err, index) in errMsgs" :key="index" class="margin-left-twenty">
              {{ err }}
            </li>
          </ul>
        </ion-col>
      </ion-row>
      <ion-row class="pad-lr-twenty full-height">
        <ion-col class="pad-ten full-height">
          <span class="text-bold"> Preset Name </span>
          <input type="text" id="save-preset-name" class="form-control" v-model="presetName" @keyup.enter="savePreset" />
        </ion-col>
      </ion-row>
    </div>
    <div class="column-stretch-footer">
      <ion-row class="ion-align-items-center pad-lr-twenty pad-top-ten pad-bottom-ten">
        <ion-col>
          <ion-row class="pad-ten ion-align-items-center">
            <ion-col>
              <button @click="closeModal()" class="prime-button button-secondary button-block">
                Cancel
              </button>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col>
          <ion-row class="pad-ten ion-align-items-center">
            <ion-col>
              <button @click="savePreset()" class="prime-button button-primary button-block margin-right-ten">
                Save Preset
              </button>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";

export default {
  name: "PopoverSavePreset",
  props: {
    allPresets: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      presetName: "",
      errors: {
        1: "No preset name provided",
        2: "No preset can be named 'Default'",
        3: "A preset with this name exists. Click 'Save' again to overwrite."
      },
      errMsgs: [],
      overwriteAttempt: 0
    };
  },
  mounted() {
    setTimeout(() => {
      const saveInSpot = document.getElementById("save-preset-name");
      saveInSpot.focus();
    }, 200);
  },
  methods: {
    async savePreset() {
      this.errMsgs = this.errMsgs.filter(err => err == this.errors[3]);

      if (!this.presetName) {
        this.errMsgs.push(this.errors[1]);
        return;
      }
      if (this.presetName.toLowerCase() === "default") {
        this.errMsgs.push(this.errors[2]);
        return;
      }

      if (this.allPresets.includes(this.presetName) && !this.errMsgs.includes(this.errors[3])) {
        this.errMsgs.push(this.errors[3]);
        return;
      }

      this.isLoading = true;
      const method = "put";
      const path = document.config.professional + this.proId + "/roster/preset/" + this.presetName;
      const rosterFilterModel = await window.localStorage.getItem("roster.filterModel");
      const rosterColumnModel = await window.localStorage.getItem("roster.columnState");
      const payload = {
        name: this.presetName,
        "roster.filterModel": JSON.parse(rosterFilterModel),
        "roster.columnState": JSON.parse(rosterColumnModel)
      };

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Preset Saved.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => {
              m.present();
            });
          EventBus.$emit("presetUpdated", this.presetName);
          this.closeModal();
        })
        .catch(error => {
          this.catchError(error, "Could not saved Preset.");
        })
        .finally(() => (this.loading = false));
    },
    closeModal() {
      this.$ionic.popoverController.dismiss({ description: null });
    }
  }
};
</script>
