<template>
  <div v-if="currentRiskScore" class="risk-score">
    <span
      v-if="!isGrouped"
      @click="showDrivers()"
      :class="[riskClass]"
      class="cursor-pointer pill text-center text-bold text-capitalize"
    >
      <ion-col>{{ currentRiskScore }}</ion-col>
      <ion-col size="5" class="pill risk-assignment-pill">
        <IconTrendingUp v-if="riskAssignment == 'rising'" class="rising" />
        <IconTrendingDown v-else-if="riskAssignment == 'falling'" class="falling" />
        <span v-else class="steady" />
        {{ riskAssignment }}
      </ion-col>
    </span>
  </div>
</template>

<script>
import ModalRiskDrivers from "@/components/Settings/Patient/ModalRiskDrivers";
import IconTrendingUp from "@/components/Global/Icons/IconTrendingUp";
import IconTrendingDown from "@/components/Global/Icons/IconTrendingDown";

export default {
  name: "RiskScoreRenderer",
  components: {
    IconTrendingUp,
    IconTrendingDown
  },
  computed: {
    isGrouped() {
      return !this.params.data.hasOwnProperty("accountid");
    },
    currentRiskScore() {
      return normalizeRiskCategory(this.params.value);
    },
    previousRiskScore() {
      return normalizeRiskCategory(this.params.data.previous_uh_risk_score_category);
    },
    riskAssignment() {
      const current = this.currentRiskScore;
      const prev = this.previousRiskScore;
      if (current === null || prev === null) {
        return "steady";
      }

      const currentValue = riskScoreValue(current);
      const prevValue = riskScoreValue(prev);

      if (prevValue < currentValue) {
        return "rising";
      } else if (prevValue > currentValue) {
        return "falling";
      }

      return "steady";
    },
    riskClass() {
      switch (this.currentRiskScore) {
        case "low":
          return "green-pill-two";
        case "moderate":
        case "moderate high":
          return "yellow-pill";
        case "high":
        case "very high":
          return "urgent-pill";
        default:
          return undefined;
      }
    },
    riskDrivers() {
      const drivers = this.params.data.current_uh_risk_score_drivers;
      if (!drivers) return [];

      let driversList = [];
      if (drivers.startsWith("[")) {
        driversList = JSON.parse(drivers).flatMap(x => x.split("<br/>"));
      } else {
        driversList = drivers.split("<br/>");
      }
      return driversList.filter(d => d.length > 0);
    }
  },
  methods: {
    showDrivers() {
      this.$ionic.modalController
        .create({
          component: ModalRiskDrivers,
          mode: "ios",
          componentProps: {
            data: {
              score: this.currentRiskScore,
              assignment: this.riskAssignment,
              drivers: this.riskDrivers
            }
          }
        })
        .then(m => m.present());
    }
  }
};

function normalizeRiskCategory(category) {
  if (!category) return category;

  if (category.startsWith("1") || category.startsWith("2")) {
    return "low";
  } else if (category.startsWith("3")) {
    return "moderate";
  } else if (category.startsWith("4")) {
    return "moderate high";
  } else if (category.startsWith("5")) {
    return "high";
  } else if (category.startsWith("6")) {
    return "very high";
  }

  return category.toLowerCase();
}

function riskScoreValue(score) {
  switch (score) {
    case "low":
      return 1;
    case "moderate":
      return 2;
    case "moderate high":
      return 3;
    case "high":
      return 4;
    case "very high":
      return 5;
    default:
      return 0;
  }
}
</script>

<style scoped>
.risk-score {
  padding: 17px 0;
}

.pill {
  display: flex;
  height: 26px;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding: 2px;
}

.pill.risk-assignment-pill {
  font-size: 11px;
  color: var(--ion-text-color);
  height: 22px;
}

.risk-assignment-pill .rising {
  fill: var(--ion-color-danger);
  max-width: 22px;
  min-width: 22px;
  vertical-align: middle;
}

.risk-assignment-pill .falling {
  fill: #70c01c;
  max-width: 22px;
  min-width: 22px;
  vertical-align: middle;
}

.risk-assignment-pill .steady {
  background-color: var(--ion-color-medium-shade);
  display: inline-block;
  height: 2px;
  width: 15px;
}
</style>
