<template>
  <ion-list mode="ios">
    <ion-item @click="sendBroadcastMessage" class="cursor-pointer">
      <ion-label color="dark" mode="ios">
        Send Broadcast Message
      </ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import ModalBroadcastMessageRoster from "./ModalBroadcastMessageRoster";

export default {
  name: "PopoverBulkActions",
  props: {
    fetchSelectedPatients: Function
  },
  methods: {
    async sendBroadcastMessage() {
      this.$ionic.popoverController.dismiss();

      this.$ionic.modalController
        .create({
          component: ModalBroadcastMessageRoster,
          mode: "ios",
          componentProps: {
            propsData: {
              fetchSelectedPatients: this.fetchSelectedPatients
            }
          }
        })
        .then(p => p.present());
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}

ion-item {
  padding: 0 5px;
}

ion-item:hover {
  background: var(--ion-color-light);
}

ion-label {
  font-weight: 500;
}

#__all-text {
  position: fixed;
  width: 1px;
  height: 1px;
  left: -9999px;
}
</style>
