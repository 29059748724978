<template>
  <div class="container">
    <span>Rows: </span>
    <span class="value">{{ countDisplay }}</span>
  </div>
</template>

<script>
export default {
  name: "RowCountStatusPanel",
  computed: {
    counts() {
      return this.params.context.getCounts();
    },
    totalCount() {
      return this.counts.totalCount;
    },
    isFiltered() {
      return this.filteredCount < this.totalCount;
    },
    filteredCount() {
      return this.counts.filteredCount;
    },
    countDisplay() {
      const total = new Intl.NumberFormat().format(this.totalCount);
      if (!this.isFiltered) {
        return total;
      }
      return `${new Intl.NumberFormat().format(this.filteredCount)} of ${total}`;
    }
  }
};
</script>

<style scoped>
.container {
  margin-left: 6px;
  margin-right: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.value {
  font-weight: bold;
}
</style>
