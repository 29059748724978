<template>
  <button
    v-if="$can(I.MODIFY_ENROLLMENT)"
    @click="handleButtonClick"
    class="prime-button button-secondary prime-action-button-container"
    :class="{
      'button-pending': isLoading
    }"
    :id="buttonID"
  >
    <EnrollmentToolText :status="enrollmentStatus">{{ buttonText }}</EnrollmentToolText>
    <ion-spinner name="dots" duration="1200" />
  </button>
  <EnrollmentToolText v-else :status="enrollmentStatus">{{ buttonText }}</EnrollmentToolText>
</template>

<script>
import EnrollmentDetails from "./EnrollmentDetails";
import EnrollmentToolText from "./EnrollmentToolText";
import { send as httpSend } from "@/services/Api";

export default {
  name: "EnrollmentTool",
  components: {
    EnrollmentToolText
  },
  props: {
    enrollmentStatus: String,
    smsNumber: String,
    enrollmentId: Number,
    beneficiaryLastName: String,
    beneficiaryId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      enrollment: {},
      isLoading: false,
      requiresAction: false,
      copy: this.$languagePack.enrollment.enrollmentDetails
    };
  },
  computed: {
    buttonID() {
      return this.enrollmentStatus + "-" + this.beneficiaryLastName + "-" + this.beneficiaryId;
    },
    buttonText() {
      return this.copy.status[this.enrollmentStatus].title;
    },
    defaultEnrollment() {
      const enrollment = {
        id: this.enrollmentId || 0,
        status: this.enrollmentStatus || "notEnrolled",
        beneficiaryId: this.beneficiaryId,
        attributes: {
          smsPhoneNumber: this.smsNumber || ""
        }
      };
      return enrollment;
    }
  },
  methods: {
    async handleButtonClick() {
      await this.getEnrollment();
      this.openModal();
    },
    async getEnrollment() {
      if (!this.enrollmentId || this.enrollmentStatus === "optout") {
        // if no enrollment, or enrollmentStatus is a non-supported status in enrollment DB model (optedout eg)
        // then return default enrollment so child components dont have to have a bunch of scenario specific checks.
        this.enrollment = this.defaultEnrollment;
      } else {
        this.isLoading = true;
        const path = document.config.enrollmentApi + this.enrollmentId;
        const method = "GET";

        try {
          const results = await httpSend({
            path,
            method,
            authToken: true
          });
          if (results.data && results.data.payload) {
            this.enrollment = results.data.payload;
          } else {
            this.enrollment = this.defaultEnrollment;
          }

          this.isLoading = false;
        } catch (err) {
          khanSolo.log(err);
          this.isLoading = false;
          this.enrollment = this.defaultEnrollment;
        }
      }
    },
    async openModal() {
      const modalProperties = {
        mode: "ios",
        component: EnrollmentDetails,
        componentProps: {
          propsData: {
            enrollment: this.enrollment
          }
        }
      };
      const modal = await this.$ionic.modalController.create(modalProperties);
      return modal.present();
    }
  }
};
</script>

<style scoped>
.prime-action-button-container {
  position: relative;
  width: 100%;
}
</style>
