<template>
  <div>
    <button v-if="params.data.last_adt_correlation_id" class="prime-button button-secondary button-block" @click="onClick()">
      <span class="text-black">
        {{ params.value }}
      </span>
    </button>
    <span v-else>
      {{ params.value }}
    </span>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import ModalEventNotification from "@/components/Settings/Patient/ModalEventNotification";

export default {
  name: "EventsRenderer",
  components: {},
  methods: {
    async onClick() {
      var details = await this.getDetails(this.params.data.last_adt_correlation_id);
      this.openModal(details, this.params.data.beneficiaryid);
    },
    getDetails(id) {
      const method = "get";
      const path = document.config.patientApi + "event/" + id;

      return httpSend({ path, method, authToken: true })
        .then(response => {
          return response.data;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error!",
              message: "There was a problem getting data.",
              duration: 4000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    openModal(detailsObj, beneId) {
      this.$ionic.modalController
        .create({
          component: ModalEventNotification,
          mode: "ios",
          componentProps: {
            propsData: {
              detailsObj,
              beneId
            }
          },
          backdropDismiss: false
        })
        .then(m => m.present());
    }
  }
};
</script>
