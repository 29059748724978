<template>
  <ion-list mode="ios">
    <ion-item lines="full" @click="addSinglePatient()" class="cursor-pointer">
      <ion-label color="dark" mode="ios">Add Patient</ion-label>
    </ion-item>
    <ion-item lines="none" @click="bulkUpload()" class="cursor-pointer">
      <ion-label color="dark" mode="ios">Bulk Patient Upload</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import ModalAddPatient from "@/components/Roster/ModalAddPatient";
import ModalAddPatientsImport from "@/components/Roster/ModalAddPatientsImport";

export default {
  name: "PopoverRosterPatient",
  data() {
    return {
      currentUser: Object
    };
  },
  methods: {
    addSinglePatient: async function() {
      this.$ionic.popoverController.dismiss();
      const modal = await this.$ionic.modalController.create({
        component: ModalAddPatient,
        mode: "ios",
        componentProps: {}
      });

      return await modal.present();
    },
    bulkUpload: async function() {
      this.$ionic.popoverController.dismiss();
      const modal = await this.$ionic.modalController.create({
        component: ModalAddPatientsImport,
        mode: "ios",
        componentProps: {}
      });

      return await modal.present();
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
