import { send } from "./Api";

const baseUrl = () => document.config.gridApi;

const getOptions = async id => {
  const request = {
    path: `${baseUrl()}/options?grid=${id}`,
    method: "get",
    authToken: true
  };
  const response = await send(request);
  return response.data;
};

const search = async (id, req, fieldModel, removeLimit) => {
  if (req) {
    window.sessionStorage.setItem(`${id}.lastRequest`, JSON.stringify(req));
  } else {
    req = JSON.parse(window.sessionStorage.getItem(`${id}.lastRequest`));
  }
  if (fieldModel) {
    req.fieldModel = fieldModel;
  }
  if (removeLimit) {
    req.endRow = null;
  }

  const request = {
    path: `${baseUrl()}/search?grid=${id}`,
    method: "post",
    payload: req,
    authToken: true
  };

  const response = await send(request);
  return response.data;
};

const initiateExport = async (id, req, fieldModel) => {
  if (!req) {
    req = JSON.parse(window.sessionStorage.getItem(`${id}.lastRequest`));
    req.endRow = null;
  }
  if (fieldModel) {
    req.fieldModel = fieldModel;
  }
  const request = {
    path: `${baseUrl()}/export?grid=${id}`,
    method: "post",
    payload: req,
    authToken: true
  };

  const response = await send(request);
  return response.data;
};

const getSetFilterValues = async fieldName => {
  const request = {
    path: `${baseUrl()}/filters?grid=roster&field=${fieldName}`,
    method: "get",
    authToken: true
  };
  const response = await send(request);
  return response.data;
};

const getExportStatus = async exportId => {
  const request = {
    path: `${baseUrl()}/export/${exportId}/status`,
    method: "get",
    authToken: true
  };
  const response = await send(request);
  return response.data;
};

const getExport = async exportId => {
  const request = {
    path: `${baseUrl()}/export/${exportId}`,
    method: "get",
    authToken: true
  };
  const response = await send(request);
  return response.data;
};

const getAdvancedFilters = async () => {
  const request = {
    path: `${baseUrl()}/advanced_filters?grid=roster`,
    method: "get",
    authToken: true
  };
  const response = await send(request);
  return response.data;
};

export default {
  getExport,
  getOptions,
  getExportStatus,
  initiateExport,
  search,
  getAdvancedFilters,
  getSetFilterValues
};
