<template>
  <div class="prime-modal">
    <ion-header class="bottom-divider">
      <ion-toolbar>
        <IconAlertAdd slot="start" class="icon-small" />
        <h5 slot="start">Enter Manual Event</h5>
        <ion-button
          slot="end"
          mode="ios"
          @click="closeModal()"
          expand="full"
          color="medium"
          fill="clear"
          ><ion-icon name="md-close"></ion-icon
        ></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="prime-modal-content">
      <div v-if="isLoading" class="text-center pad-ten loading-overlay center-center">
        <ion-spinner name="lines" class="spinner-large" color="dark"></ion-spinner>
      </div>
      <div v-else>
        <div v-if="selectedEvent">
          <div class="margin-top-ten">
            {{ selectedEvent ? "Type of event:" : "Select the type of event:" }}
          </div>
          <FormErrors :errors="errors" />
          <div class="prime-card margin-top-ten text-center pad-twenty">
            <p class="text-bold margin-death">
              {{ selectedEvent.eventName ? selectedEvent.eventName : "" }}
            </p>
          </div>
          <div
            v-if="selectedEvent.primeEventType == 'medication_adherence'"
            class="margin-top-twenty"
          >
            <h6>Initial Medication Concern:</h6>
            <ion-row class="ion-align-items-center margin-left-twenty margin-top-twenty">
              <ion-col size="auto">
                <div class="checkbox-radio margin-right-ten">
                  <input
                    id="imc-bp"
                    type="radio"
                    value="Blood Pressure"
                    v-model="selectedMedConcern"
                  />
                  <label for="imc-bp"></label>
                </div>
              </ion-col>
              <ion-col size="auto" class="valign">Blood Pressure</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center margin-left-twenty margin-top-ten">
              <ion-col size="auto">
                <div class="checkbox-radio margin-right-ten">
                  <input
                    id="imc-cholesterol"
                    type="radio"
                    value="Cholesterol"
                    v-model="selectedMedConcern"
                  />
                  <label for="imc-cholesterol"></label>
                </div>
              </ion-col>
              <ion-col size="auto" class="valign">Cholesterol</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center margin-left-twenty margin-top-ten">
              <ion-col size="auto">
                <div class="checkbox-radio margin-right-ten">
                  <input
                    id="imc-diabetes"
                    type="radio"
                    value="Diabetes"
                    v-model="selectedMedConcern"
                  />
                  <label for="imc-diabetes"></label>
                </div>
              </ion-col>
              <ion-col size="auto" class="valign">Diabetes</ion-col>
            </ion-row>
          </div>
          <div
            v-else-if="selectedEvent.primeEventType == 'visit_scheduling'"
            class="margin-top-twenty"
          >
            <h6>Visit Scheduling Method:</h6>
            <h5>How should a patient schedule their visit?</h5>
            <ion-row class="ion-align-items-center margin-left-twenty margin-top-ten">
              <ion-col size="auto">
                <div class="checkbox-radio margin-right-ten">
                  <input id="imc-false" type="radio" value="call" v-model="selectedRVCommOption" />
                  <label for="imc-false"></label>
                </div>
              </ion-col>
              <ion-col size="auto" class="valign">Call Scheduling Phone Number</ion-col>
            </ion-row>
            <ion-row class="ion-align-items-center margin-left-twenty margin-top-ten">
              <ion-col size="auto">
                <div class="checkbox-radio margin-right-ten">
                  <input id="imc-true" type="radio" value="link" v-model="selectedRVCommOption" />
                  <label for="imc-true"></label>
                </div>
              </ion-col>
              <ion-col size="auto" class="valign">Use MyChart Link</ion-col>
            </ion-row>
          </div>
        </div>

        <div v-else>
          <div v-if="$can(I.VIEW_ADT_EVENTS)">
            <p class="text-bold margin-top-twenty">Admissions and Discharges</p>
            <!-- adtEvents -->
            <ion-card
              color="white"
              v-for="event in adtEvents"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventName }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
          <div v-if="$can(I.VIEW_AHP_EVENTS)">
            <p class="text-bold margin-top-twenty">AdvantMed</p>
            <ion-card
              color="white"
              v-for="event in ahpEvents"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
          <div v-if="$can(I.VIEW_ADVANCED_DIRECTIVE)">
            <p class="text-bold margin-top-twenty">Advance Directive</p>
            <ion-card
              color="white"
              v-for="event in advanceddirective"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
          <div v-if="$can(I.VIEW_VISIT_COORDINATION)">
            <p class="text-bold margin-top-twenty">Visit Coordination</p>
            <!-- visitCoordination -->
            <ion-card
              color="white"
              v-for="event in visitCoordination"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventName }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
          <div>
            <p class="text-bold margin-top-twenty">Education</p>
            <ion-card
              color="white"
              v-for="event in education"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventName }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
          <div v-if="$can(I.VIEW_CARE_GAP_EVENTS)">
            <p class="text-bold margin-top-twenty">Open Alerts - Quality Considerations</p>
            <ion-card
              color="white"
              v-for="event in careGaps"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
          <div v-if="$can(I.VIEW_QUALITY_CAMPAIGN_EVENTS)">
            <p class="text-bold margin-top-twenty">Open Alerts - Quality Campaigns</p>
            <ion-card
              color="white"
              v-for="event in qualityCampaigns"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
          <div v-if="$can(I.VIEW_LCMC_EVENTS)">
            <p class="text-bold margin-top-twenty">LCMC</p>
            <ion-card
              color="white"
              v-for="event in lcmcEvents"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
          <div v-if="$can(I.VIEW_BHAR_EVENTS)">
            <p class="text-bold margin-top-twenty">BHAR</p>
            <ion-card
              color="white"
              v-for="event in bharEvents"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>

          <div v-if="$can(I.VIEW_HEALTHY_HEART_EVENTS)">
            <p class="text-bold margin-top-twenty">Heart Health</p>
            <ion-card
              color="white"
              v-for="event in heartHealth"
              :key="event.id"
              class="compact-shadow margin-death margin-top-ten cursor-pointer"
              @click="selectEvent(event)"
            >
              <ion-row class="ion-align-items-center pad-five">
                <ion-col>
                  <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                </ion-col>
                <ion-col size="auto">
                  <IconCarrot class="icon-medium icon-grey valign" />
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row v-if="selectedEvent">
          <ion-col>
            <button
              @click="selectedEvent = undefined"
              class="prime-button button-secondary button-block"
            >
              <span>Back</span>
            </button>
          </ion-col>
          <ion-col class="margin-left-ten">
            <button
              @click="saveEvent(selectedEvent)"
              class="prime-button button-primary button-block"
            >
              <span>Finish</span>
            </button>
          </ion-col>
        </ion-row>
        <ion-row v-else>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-secondary button-block">
              <span>Cancel</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import FormErrors from "@/components/Enrollment/FormErrors";
import IconAlertAdd from "@/components/Global/Icons/IconAlertAdd";
import IconCarrot from "@/components/Global/Icons/IconCarrot";
import { send as httpSend } from "@/services/Api";
import { addIcons } from "ionicons";
import { calendar } from "ionicons/icons";

addIcons({
  "md-calendar": calendar.md
});

export default {
  name: "ModalEventEntry",
  components: {
    IconAlertAdd,
    IconCarrot,
    FormErrors
  },
  data() {
    return {
      selectedEvent: undefined,
      errors: [],
      selectedMedConcern: undefined,
      selectedRVCommOption: "0",
      date: new Date(),
      time: undefined,
      dxDescriptionError: false,
      facilityError: false,
      careGaps: [
        {
          id: 1,
          primeEventType: "medication_adherence",
          eventTypeTitle: "Medication Adherence",
          eventName: "Medication Adherence",
          beneficiaryId: undefined,
          accountId: undefined,
          medication_measure: undefined,
          event_time: undefined,
          program_id: 10
        }
      ],
      qualityCampaigns: [
        {
          id: 2,
          primeEventType: "diabetes",
          beneficiaryId: undefined,
          eventTypeTitle: "Diabetes",
          eventName: "Diabetes",
          event_type: "diabetes",
          program_id: 11
        },
        {
          id: 3,
          primeEventType: "blood_pressure",
          beneficiaryId: undefined,
          eventTypeTitle: "Blood Pressure",
          eventName: "Blood Pressure",
          event_type: "blood_pressure",
          program_id: 12
        },
        {
          id: 4,
          primeEventType: "statins",
          beneficiaryId: undefined,
          eventTypeTitle: "Statins",
          eventName: "Statins",
          event_type: "statins",
          program_id: 13
        },
        {
          id: 5,
          primeEventType: "bcs",
          beneficiaryId: undefined,
          eventTypeTitle: "Breast Cancer Screening",
          eventName: "Breast Cancer Screening",
          event_type: "bcs",
          program_id: 14
        },
        {
          id: 6,
          primeEventType: "ccs_0",
          beneficiaryId: undefined,
          eventTypeTitle: "Colorectal Cancer Screening - Initial Outreach",
          eventName: "Colorectal Cancer Screening - Initial Outreach",
          event_type: "ccs_initial_outreach",
          program_id: 15
        },
        {
          id: 7,
          primeEventType: "flu_vaccine_reminder",
          beneficiaryId: undefined,
          eventTypeTitle: "Vaccine Reminder",
          eventName: "Vaccine Reminder",
          event_type: "flu_vaccine_reminder",
          program_id: 3
        }
      ],
      lcmcEvents: [
        {
          id: 8,
          primeEventType: "ccs_lcmc",
          beneficiaryId: undefined,
          eventTypeTitle: "Colorectal Cancer Screening - LCMC",
          eventName: "Colorectal Cancer Screening - LCMC",
          event_type: "ccs_lcmc_outreach",
          program_id: 19
        }
      ],
      adtEvents: [
        {
          id: 9,
          primeEventType: "adt_a04",
          beneficiaryId: undefined,
          eventTypeTitle: "Admissions and Discharges",
          eventName: "Patient Registered (ADT_A04)",
          event_type: "Patient Registered at ED",
          facility_name: undefined,
          event_description: undefined,
          dx_description: undefined,
          event_time: undefined,
          program_id: 1
        },
        {
          id: 10,
          primeEventType: "adt_a01",
          beneficiaryId: undefined,
          eventTypeTitle: "Admissions and Discharges",
          eventName: "Patient Admit/Visit Notification (ADT_A01)",
          event_type: "Patient Admitted",
          facility_name: undefined,
          event_description: undefined,
          dx_description: undefined,
          event_time: undefined,
          program_id: 1
        },
        {
          id: 11,
          primeEventType: "adt_a03",
          beneficiaryId: undefined,
          eventTypeTitle: "Admissions and Discharges",
          eventName: "Patient Discharge/End Visit (ADT_A03)",
          event_type: "Patient Discharged",
          facility_name: undefined,
          event_description: undefined,
          dx_description: undefined,
          event_time: undefined,
          program_id: 1
        }
      ],
      visitCoordination: [
        {
          id: 12,
          primeEventType: "visit_scheduling",
          beneficiaryId: undefined,
          eventTypeTitle: "Visit Type",
          eventName: "Routine Visit Scheduling",
          event_type: "Routine Visit",
          visit_type: undefined,
          contact_method: undefined,
          program_id: 16
        },
        {
          id: 18,
          primeEventType: "post_discharge",
          beneficiaryId: undefined,
          eventTypeTitle: "Visit Type",
          eventName: "Post-Discharge Visit Scheduling",
          event_type: "Post-Discharge",
          program_id: 17
        }
      ],
      ahpEvents: [
        {
          id: 13,
          primeEventType: "healthy_house_call",
          beneficiaryId: undefined,
          eventTypeTitle: "Healthy House Call",
          eventName: "Healthy House Call",
          event_type: "Healthy House Call",
          program_id: 20
        }
      ],
      advanceddirective: [
        {
          id: 14,
          primeEventType: "advanced_directive",
          beneficiaryId: undefined,
          eventTypeTitle: "Advance Directives",
          eventName: "Advance Directives",
          event_type: "Advance Directives",
          program_id: 22
        }
      ],
      education: [
        {
          id: 15,
          primeEventType: "ed_utilization",
          beneficiaryId: undefined,
          eventTypeTitle: "Education",
          eventName: "ED Utilization",
          event_type: "ED Utilization",
          visit_type: undefined,
          contact_method: undefined,
          program_id: 21
        }
      ],
      bharEvents: [
        {
          id: 16,
          primeEventType: "bhar_post_discharge",
          beneficiaryId: undefined,
          eventTypeTitle: "Baptist Health Post Discharge Follow Up",
          eventName: "Baptist Health Post Discharge Follow Up",
          event_type: "Post Discharge",
          program_id: 22
        }
      ],
      heartHealth: [
        {
          id: 17,
          primeEventType: "heart_health",
          beneficiaryId: undefined,
          eventTypeTitle: "Heart Health",
          eventName: "Heart Health",
          event_type: "Heart Health",
          program_id: 18
        }
      ],
      isLoading: false,
      communicationPrefTimes: this.$languagePack.enrollmentForm.communicationPrefTimes
    };
  },
  methods: {
    onTimeChange(evt) {
      this.time = evt.target.value;
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    selectEvent(event) {
      this.selectedEvent = event;
    },
    UUID() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },
    checkForm() {
      this.errors = [];
      if (!this.selectedEvent.facility_name) {
        this.errors.push("Please enter a Facility Name");
        this.facilityError = true;
      }
      if (!this.selectedEvent.dx_description) {
        this.errors.push("Please enter a Please enter a Diagnosis Description");
        this.dxDescriptionError = true;
      }
      if (this.errors.length == 0) {
        this.saveEvent(this.selectedEvent);
      }
    },
    saveEvent(event) {
      this.isLoading = true;
      event.beneficiaryId = [this.beneficiaryId];
      event.accountId = [this.accountId];
      event.correlation_id = this.UUID();
      event.event_time = this.date;
      this.selectedMedConcern ? (event.medication_measure = this.selectedMedConcern) : "";

      this.selectedRVCommOption ? (event.contact_method = this.selectedRVCommOption) : "call";

      const payload = event;
      const method = "post";
      const path = document.config.eventEntry;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "The Event has been entered.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not enter manual event. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.closeModal();
        });
    }
  }
};
</script>

<style scoped>
.message {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
ion-footer {
  padding: 20px;
}

.prime-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
}

.input-icon {
  position: absolute;
  padding: 10px 10px 10px 10px;
}

.pad-left-twenty {
  padding-left: 35px;
}
.error {
  color: red;
}
.error input {
  border: 3px solid red;
}
</style>
