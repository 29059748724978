<template>
  <div class="prime-grid ag-theme-alpine">
    <AgGridVue :style="{ height: `calc(100vh - ${divHeight}px)` }" :gridOptions="gridOptions"
      :rowModelType="rowModelType" :serverSideStoreType="serverSideStoreType" :suppressCsvExport="true"
      :serverSideDatasource="serverSideDatasource" rowHeight="62" @grid-ready="onGridReady"
      @filter-changed="onFilterChanged" @sort-changed="saveState" @column-resized="saveState"
      @column-visible="saveState" @column-pivot-changed="saveState" @column-row-group-changed="saveState"
      @column-value-changed="saveState" @column-moved="saveState" @column-pinned="saveState" @model-updated="saveState"
      :modules="modules"></AgGridVue>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "@ag-grid-community/vue";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { EventBus } from "@/services/Events.js";
import AdvancedFilters from "@/components/Global/AdvancedFilters";
import ActionRenderer from "@/components/Global/Grid/ActionRenderer";
import AdvancedFilter from "@/components/Global/Grid/AdvancedFilter";
import AvailableChannelsRenderer from "@/components/Global/Grid/AvailableChannelsRenderer";
import DurationRenderer from "@/components/Global/Grid/DurationRenderer";
import EnrollmentStatusRenderer from "@/components/Global/Grid/EnrollmentStatusRenderer";
import EventsRenderer from "@/components/Global/Grid/EventsRenderer";
import RiskScoreRenderer from "@/components/Global/Grid/RiskScoreRenderer";
import RowCountStatusPanel from "@/components/Global/Grid/RowCountStatusPanel";
import SimpleTextRenderer from "@/components/Global/Grid/SimpleTextRenderer";
import { send as httpSend } from "@/services/Api";
import GridApi from "@/services/GridApi";
import options from "../Roster/options";

export default {
  name: "PrimeGrid",
  components: {
    AgGridVue,
    ActionRenderer,
    AvailableChannelsRenderer,
    DurationRenderer,
    EnrollmentStatusRenderer,
    RiskScoreRenderer,
    EventsRenderer,
    RowCountStatusPanel,
    SimpleTextRenderer,
    AdvancedFilters,
    AdvancedFilter
  },
  props: {
    view: String,
  },
  data() {
    return {
      currentRetryAttempts: 1,
      gridOptions: options,
      viewportHeight: window.innerHeight,
      divHeight: 300,
      gridApi: null,
      columnApi: null,
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      serverSideDatasource: null,
      statusBar: null,
      sideBar: null,
      totalCount: 0,
      filteredCount: 0,
      modules: [ClipboardModule, ColumnsToolPanelModule, FiltersToolPanelModule, MenuModule, RowGroupingModule, ServerSideRowModelModule, SetFilterModule, StatusBarModule]
    };
  },
  mounted() {
    EventBus.$on("gridHeight", gridHeight => {
      if (gridHeight === 0) {
        this.divHeight = 300;
      } else if (gridHeight === 20) {
        gridHeight = 100;
        this.divHeight = gridHeight + 320;
      } else if (gridHeight > 20) {
        this.divHeight = gridHeight + 290;
      }
      // On Refreshing the screen the extra div is being added by ag-grid. Setting the height explicitly 
      if(this.divHeight > 400) {
        this.divHeight = 390;
      }
    });
  },
  async beforeMount() {
    this.gridOptions.cacheBlockSize = this.gridOptions.cacheBlockSize || 500;
    this.gridOptions.statusBar = {
      statusPanels: [
        {
          statusPanel: "RowCountStatusPanel",
          key: "rowCount",
          align: "left",
          statusPanelParams: { context: { getCounts: this.getCounts } }
        }
      ]
    };
    this.gridOptions.columnDefs.forEach(group => {
      if (group.children) {
        group.children.forEach(col => {
          if (col.filter === "agSetColumnFilter" && !col.filterParams) {
            col.filterParams = {
              values: async params => {
                const data = await GridApi.getSetFilterValues(col.field);
                return params.success(data);
              }
            };
          }
        });
      }
    });
  },
  methods: {
    onFilterChanged(e) {
      const filterModel = e.api.getFilterModel();
      const flatColumnOrder = this.gridOptions.columnDefs.flatMap(group => group.children);
      for (const coldId in filterModel) {
        const colDef = this.gridApi.getColumnDef(coldId);
        const headerName = colDef.headerName;
        filterModel[coldId]['columnName'] = headerName;
        filterModel[coldId]['condition'] = this.formatFilterType(filterModel[coldId].type)
      }
      const reorderedObject = {};
      flatColumnOrder.forEach(item => {
        let key = '';
        if (item && item.field !== undefined) {
          key = item.field
        }
        else if (item && item.headerName !== undefined) {
          key = item.headerName
        }

        if (filterModel.hasOwnProperty(key)) {
          reorderedObject[key] = filterModel[key];
        }
      });

      // Ensure all entries in filterModel are included in reorderedObject
      Object.keys(filterModel).forEach(key => {
        if (!reorderedObject.hasOwnProperty(key)) {
          reorderedObject[key] = filterModel[key];
        }
      });

      // Add 'quality_considerations', 'diagnosis_considerations', and 'reported_conditions' in the specified order at the end
      ['quality_considerations', 'diagnosis_considerations', 'reported_conditions'].forEach(extraKey => {
        if (filterModel.hasOwnProperty(extraKey)) {
          reorderedObject[extraKey] = filterModel[extraKey];
        }
      });

      this.$emit("filter-selected", reorderedObject);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      params.columnApi.applyColumnState({
        state: this.getItemFromState("columnState"),
        applyOrder: true
      });

      params.api.setFilterModel(this.getItemFromState("filterModel"));
      this.$emit("filter-selected", this.getItemFromState("filterModel"));
      this.serverSideDatasource = createDatasource(this.view, GridApi, this.onDataFetched, this.onRequestFailed);
      this.$emit("grid-ready", {
        api: params.api,
        export: this.export,
        fetchSelectedRows: this.fetchSelectedRows
      });

    },
    formatFilterType(filterType) {
      // Add more mappings as needed
      const typeMappings = {
        contains: "Contains",
        notContains: "Not contains",
        equals: "Equals",
        notEqual: "Not equal",
        startsWith: "Starts with",
        endsWith: "Ends with",
        blank: "Blank",
        notBlank: "Not blank",
        inRange: "In range",
        greaterThan: "Greater than",
        lessThan: "Less than"

        // Add other mappings as needed
      };

      return typeMappings[filterType] || filterType;
    },
    choosePreset() {
      this.filtersHaveChanged = false;
    },
    saveState() {
      if (!this.columnApi) return;

      this.saveItemInState("columnState", this.columnApi.getColumnState());
      this.saveItemInState("filterModel", this.gridApi.getFilterModel());
    },
    saveItemInState(key, value) {
      this.filtersHaveChanged = true;
      window.localStorage.setItem(`${this.view}.${key}`, JSON.stringify(value));
    },
    getItemFromState(key) {
      return JSON.parse(window.localStorage.getItem(`${this.view}.${key}`));
    },
    async export() {
      const columns = this.columnApi.getAllDisplayedColumns();
      const fields = columns
        .map(col => col.getColDef())
        .filter(col => col.field)
        .map(col => col.field);
      const result = await GridApi.initiateExport(this.view, null, fields);

      const interval = setInterval(async () => {
        await this.checkExportStatus(interval, result.export_id);
      }, 1000);
    },
    async checkExportStatus(interval, exportId) {
      const statusResult = await GridApi.getExportStatus(exportId);
      this.$emit("export-status", statusResult);
      this.$emit("export-progress", statusToProgress(statusResult.status));

      if (["FINISHED", "ABORTED", "FAILED"].includes(statusResult.status)) {
        clearInterval(interval);
        this.$emit("export-complete", statusResult);
      }
      if (statusResult.status === "FINISHED") {
        this.downloadExport(exportId);
      }
    },
    async downloadExport(exportId) {
      const exportResult = await GridApi.getExport(exportId);
      const response = await httpSend({
        path: exportResult.url,
        method: "get",
        responseType: "blob"
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.view}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    fetchSelectedRows(fields) {
      if (this.gridApi.getDisplayedRowCount() <= this.gridOptions.cacheBlockSize) {
        const rows = [];
        this.gridApi.forEachNode(node => rows.push(node.data));
        return Promise.resolve(rows);
      }
      return GridApi.search(this.view, null, fields, true).then(data => data.rows);
    },
    onDataFetched(data) {
      this.currentRetryAttempts = 1;
      this.totalCount = data.total_count;
      this.filteredCount = data.filtered_count;
    },
    onRequestFailed(error) {
      if (!error || !error.message) {
        this.showToast("Unknown error");
        return;
      }

      if (error.message.includes("504") && this.currentRetryAttempts < 3) {
        this.currentRetryAttempts += 1;
        khanSolo.log("request timed out, making attempt " + this.currentRetryAttempts);
        this.gridApi.retryServerSideLoads();
      } else {
        this.currentRetryAttempts = 1;
        this.showToast(error.message);
      }
    },
    showToast(message) {
      this.$ionic.toastController
        .create({
          header: "Failed to load the patient roster",
          message: message,
          duration: 7000,
          position: "top"
        })
        .then(m => m.present());
    },
    getCounts() {
      return {
        totalCount: this.totalCount,
        filteredCount: this.filteredCount
      };
    }
  }
};

const createDatasource = (grid_name, api, onDataFetched, onRequestFailed) => {
  return {
    // called by the grid when more rows are required
    getRows: params => {
      api
        .search(grid_name, params.request)
        .then(data => {
          params.success({
            rowData: data.rows,
            rowCount: data.row_count
          });
          onDataFetched(data);
        })
        .catch(ex => {
          params.fail();
          onRequestFailed(ex);
        });
    }
  };
};

const statusToProgress = status => {
  switch (status) {
    case "STARTED":
      return 0.05;
    case "EXTRACTED":
      return 0.25;
    case "PROCESSING":
      return 0.5;
    case "SAVING":
      return 0.75;
    case "FINISHED":
      return 1;
    default:
      return 0;
  }
};
</script>

<style lang="scss">
@import "~@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  // https://www.ag-grid.com/vue-data-grid/themes-customising/#full-list-of-theme-parameters
  @include ag-theme-alpine((row-height: 62px,
      font-family: inherit,
      header-background-color: null,
      border-color: rgb(242, 243, 245),
      secondary-border-color: ag-derived(border-color),
      row-border-color: ag-derived(secondary-border-color),
      header-column-resize-handle: true,
      header-column-resize-handle-width: 4px,
      header-column-resize-handle-color: ag-derived(border-color)));

  .ag-root-wrapper {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  .ag-root-wrapper,
  .ag-root-wrapper-body {
    // height: 86%;
    // height: calc(100vh - 300px);

  }
}

.prime-grid {
  width: 100%;
  height: 100%;
}
</style>
