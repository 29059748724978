<template>
  <ion-list mode="ios">
    <ion-item lines="full" @click="goTo('/patient-settings/general-info/')" class="cursor-pointer">
      <IconGrid class="icon-small margin-right-ten" />
      <ion-label color="dark" mode="ios">Go To Patient Overview</ion-label>
    </ion-item>
    <!-- <ion-item lines="full" class="cursor-pointer">
      <IconCheckCircle class="icon-small margin-right-ten" />
      <ion-label color="dark" mode="ios">Create New Task</ion-label>
    </ion-item>
    <ion-item lines="full" class="cursor-pointer">
      <IconPrograms class="icon-small margin-right-ten" />
      <ion-label color="dark" mode="ios">Add To Program</ion-label>
    </ion-item> -->
    <ion-item v-if="$can(I.ADD_CDE_REQUEST)" lines="full" @click="goTo('/patient-settings/quality-considerations-bulk/')" class="cursor-pointer">
      <IconArrow class="icon-small margin-right-ten" />
      <ion-label color="dark" mode="ios">Go To CDE Bulk Entry</ion-label>
    </ion-item>
    <ion-item v-if="$can(I.ACCESS_EVENT_TRIGGER)" lines="full" @click="openModal(ModalEventEntry, beneficiaryId, accountId)" class="cursor-pointer">
      <IconAlertAdd class="icon-small margin-right-ten" />
      <ion-label color="dark" mode="ios">Enter Manual Event</ion-label>
    </ion-item>
    <ion-item v-if="$can(I.VIEW_MEDICAL_HISTORY)" class="cursor-pointer" @click="printPatientPDF('overview PDF')">
      <IconPrint class="icon-small margin-right-five" />
      <ion-label color="dark" mode="ios">Print Patient Overview PDF</ion-label>
    </ion-item>
  </ion-list>
</template>

<script>
import ModalPatientPDF from "../Settings/Patient/ModalPatientPDF.vue";
// import ModalAddPatient from "@/components/Roster/ModalAddPatient";
// import ModalAddPatientsImport from "@/components/Roster/ModalAddPatientsImport";
import IconGrid from "@/components/Global/Icons/IconGrid";
import IconArrow from "@/components/Global/Icons/IconArrow";
// import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
// import IconPrograms from "@/components/Global/Icons/IconPrograms";
import IconAlertAdd from "@/components/Global/Icons/IconAlertAdd";
import IconPrint from "@/components/Global/Icons/IconPrint";
import ModalEventEntry from "@/components/Global/ModalEventEntry";
// import router from "@/router";

export default {
  name: "PopoverRosterPatientOptions",
  components: {
    IconGrid,
    IconAlertAdd,
    IconPrint,
    IconArrow
  },
  props: {
    rowData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      currentUser: Object,
      ModalEventEntry: ModalEventEntry
    };
  },
  methods: {
    addSinglePatient: async function() {},
    goTo(place) {
      this.$ionic.popoverController.dismiss();
      this.$mypcpRouter.push({
        path: place + this.beneficiaryId
      });
    },
    openModal(componentName, beneId, acctId) {
      this.$ionic.popoverController.dismiss();
      this.$ionic.modalController
        .create({
          component: componentName,
          mode: "ios",
          componentProps: {
            data: { beneficiaryId: beneId, accountId: acctId }
          }
        })
        .then(m => m.present());
    },
    async printPatientPDF(pdfType) {
      this.$ionic.popoverController.dismiss();
      const modal = await this.$ionic.modalController.create({
        component: ModalPatientPDF,
        mode: "ios",
        cssClass: "prime-modal-xwide",
        componentProps: { propsData: { pdfType, beneficiaryId: this.beneficiaryId } }
      });

      return await modal.present();
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
</style>
