<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconPersonAdd slot="start" class="icon-small" />
        <h5 slot="start">Add Patient</h5>
        <div class="prime-modal-header-steps-container" slot="end">
          <span>Step {{ stepNumber }} of 3</span>
          <div class="prime-modal-header-steps-pager" :class="{ active: stepNumber == 1 }"></div>
          <div class="prime-modal-header-steps-pager" :class="{ active: stepNumber == 2 }"></div>
          <div class="prime-modal-header-steps-pager" :class="{ active: stepNumber == 3 }"></div>
        </div>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-add-patient-pages">
      <div v-if="errors.length > 0" class="errors margin-lr-ten">
        <IconPersonAdd class="icon-small" />
        <div>
          <div v-for="error in errors" :key="error">
            {{ error + " " }}
          </div>
        </div>
      </div>

      <ion-slides pager="false" ref="slider" style="min-height: 100%;">
        <ion-slide>
          <div class="prime-add-patient-slide-content">
            <h6>First Name</h6>
            <input class="form-control" type="text" placeholder="Enter First Name" v-model.trim="patientDataModel.firstName" />
            <h6>Last Name</h6>
            <input class="form-control" type="text" placeholder="Enter Last Name" v-model.trim="patientDataModel.lastName" />
            <h6>ID</h6>
            <input class="form-control" type="text" placeholder="Enter Patient ID" v-model.trim="patientDataModel.mrn" />
            <h6>Date of Birth</h6>
            <div class="prime-v-date-picker-container">
              <v-date-picker
                v-model="patientDataModel.dob"
                :input-debounce="500"
                :popover="{
                  visibility: 'click',
                  placement: 'top'
                }"
                popover-align="center"
                :input-props="{
                  class: 'form-control'
                }"
                class="prime-v-date-picker"
                :masks="{
                  input: ['MMM, D, YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD']
                }"
              />
              <ion-icon name="md-calendar"></ion-icon>
            </div>
          </div>
        </ion-slide>

        <ion-slide class="full-height" style="position:relative;">
          <!-- STEP TWO -->
          <!-- {{pcpsOnly}} -->
          <div class="prime-add-patient-slide-content" style="padding-bottom:0;">
            <h6>Primary Care Provider *</h6>
            <div class="prime-ion-select">
              <select class="add-patient-select" @change="pcpChange($event.target.value)">
                <option value="" disabled selected>{{ professionals ? "Select Patient's PCP" : "No Care Team Members to display" }}</option>
                <option v-for="(professional, key) in pcpsOnly" :key="key" :value="professional.id">{{ professional.firstName + " " + professional.lastName }} </option>
              </select>
            </div>
            <h6 style="margin-top: 0;margin-bottom: 5px;" v-if="defaultCareTeam.length > 0">
              PCP Assigned Care Team
            </h6>
            <ion-card v-for="(member, key) in defaultCareTeam" :key="key" class="prime-professional-default-card">
              <div class="avatar" v-if="member.firstName && member.lastName">
                {{ member.firstName.charAt(0) + member.lastName.charAt(0) }}
              </div>
              <div>
                <div class="prime-professional-card-name">
                  {{ member.title | formatDisplayName(member.firstName, member.lastName, member.suffix) }}
                </div>
                <div class="prime-professional-card-info">
                  <span v-if="member.role">{{ member.role }} </span>
                </div>
              </div>
            </ion-card>

            <h6 style="margin-top: 15px;" v-if="availableCareTeam.length > 0">
              Select Other Care Team Members
            </h6>
          </div>
          <div v-if="professionals" class="prime-add-patient-slide-content care-team-section">
            <ion-card v-for="(professional, key) in availableCareTeam" :key="key" class="prime-professional-card">
              <div class="checkbox">
                <input :id="key" type="checkbox" color="primary" @change="careTeamSelections(professional)" />
                <label v-if="dataModel.careTeamMemberIds.includes(professional.id)" class="checked" :for="key"></label>
                <label v-else class="unchecked" :for="key"></label>
              </div>
              <div class="avatar" v-if="professional.firstName && professional.lastName">
                {{ professional.firstName.charAt(0) + professional.lastName.charAt(0) }}
              </div>
              <div>
                <div class="prime-professional-card-name">
                  {{ professional.firstName + " " + professional.lastName }}
                </div>
                <!-- <div class="prime-professional-card-info">
                  Nurse Practitioner
                </div> -->
              </div>
            </ion-card>
          </div>
          <div v-else class="prime-add-patient-slide-content care-team-section">
            <h2 class="text-muted text-center">
              No Care Team Members to display
            </h2>
          </div>
        </ion-slide>

        <ion-slide>
          <!-- STEP THREE -->
          <!-- {{dataModel}} -->
          <div class="prime-add-patient-slide-content">
            <h6>Phone Number - For Texting</h6>
            <input class="form-control" type="text" placeholder="Enter Phone Number" v-model.trim="dataModel.smsPhoneNumber" />
            <h6>Gender</h6>
            <div class="prime-ion-select">
              <select class="add-patient-select" @change="dataModel.gender = $event.target.value">
                <option value="" disabled selected>Select Gender</option>
                <option value="female">Female</option>
                <option value="male">Male</option>
                <option value="not_specified">Unspecified</option>
              </select>
            </div>
            <!-- <div v-if="$can(I.INITIATE_ENROLLMENT)" class="prime-form-block-container">
              <div class="prime-form-block-title-row">
                <div class="prime-form-block-title">
                  <h6>Enroll This Patient?</h6>
                </div>
                <div class="prime-form-block-input">
                  <span>{{ dataModel.initiateEnrollment ? "Yes" : "No" }}</span>
                  <ion-toggle @ionChange="dataModel.initiateEnrollment = $event.detail.checked" :checked="dataModel.initiateEnrollment" color="primary" mode="ios"></ion-toggle>
                </div>
              </div>
              <div class="prime-form-block-description">
                Patient will be sent a link to the enrollment form to gather additional information and to provide their consent and signature.
              </div>
            </div> -->
            <div class="prime-form-block-container">
              <div class="prime-form-block-title-row">
                <div class="prime-form-block-title">
                  <h6>Mark This Patient As Priority?</h6>
                </div>
                <div class="prime-form-block-input">
                  <span>{{ dataModel.vip ? "Yes" : "No" }}</span>
                  <ion-toggle @ionChange="dataModel.vip = $event.detail.checked" color="primary" mode="ios"></ion-toggle>
                </div>
              </div>
              <div class="prime-form-block-description">
                When a patient is marked Priority, their incoming communications will be automatically set to High Priority, and will be given priority status.
              </div>
            </div>
          </div>
        </ion-slide>
      </ion-slides>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="closeModal()" class="prime-button button-secondary button-block" v-if="stepNumber == 1">
              Cancel
            </button>
            <button @click="previousStep()" class="prime-button button-secondary button-block" v-else>
              Back
            </button>
          </ion-col>
          <ion-col>
            <button @click="checkForm()" class="prime-button button-block" :class="{ 'button-pending': addPatientLoading }">
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span v-if="stepNumber !== 3">Next</span>
              <span v-else>Finish</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { calendar } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import isValidPhoneNumber from "@/utils/FormHelpers/isValidPhoneNumber";
import formatPhoneNumber from "@/utils/FormHelpers/formatPhoneNumber";
import { EventBus } from "@/services/Events";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";

addIcons({
  "md-calendar": calendar.md
});

export default {
  name: "ModalAddPatient",
  components: {
    IconPersonAdd
  },
  data() {
    return {
      stepNumber: 1,
      swiperOption: {
        allowTouchMove: false,
        spaceBetween: 40,
        autoHeight: false
      },
      ionSelectOptions: {
        mode: "ios",
        cssClass: "popover-width-sm"
      },
      patientDataModel: {
        firstName: null,
        lastName: null,
        mrn: null,
        dob: null
      },
      dataModel: {
        gender: null,
        assignedProfessionalId: null,
        careTeamMemberIds: [],
        voicePhoneNumber: null,
        smsPhoneNumber: null,
        enrollmentBehavior: "optIn",
        initiateEnrollment: false,
        vip: false,
        email: null
      },
      addPatientLoading: false,
      professionals: [],
      defaultCareTeam: [],
      errors: [],
      availableCareTeam: []
    };
  },
  methods: {
    pcpChange: function(pcpId) {
      this.dataModel.assignedProfessionalId = pcpId;
      this.getDefaultCareTeam(pcpId);
    },
    careTeamSelections: function(professional) {
      const index = this.dataModel.careTeamMemberIds.indexOf(professional.id);
      if (index > -1) {
        this.dataModel.careTeamMemberIds.splice(index, 1);
      } else {
        this.dataModel.careTeamMemberIds.push(professional.id);
      }
    },
    closeModal: function() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    nextStep: function() {
      this.stepNumber++;
      this.$refs.slider.slideNext();
    },
    previousStep: function() {
      this.duplicateFound = false;
      this.$refs.slider.slidePrev();
      this.errors = [];
      this.stepNumber = this.stepNumber - 1;
    },
    getProfessionals: function() {
      const method = "get";
      const path = document.config.professionals;
      const payload = null;

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          this.professionals = result.data;
          this.getAvailableCareTeamMembers(this.dataModel.assignedProfessionalId);
        })
        .catch(() => {
          this.errors.push("There was a problem trying to get care team list");
        });
    },
    getAvailableCareTeamMembers: function(pcpId) {
      const t = this;
      this.availableCareTeam = this.professionals.filter(function(professional) {
        return professional.id != pcpId && !t.isDefaultCareTeamMember(professional.id);
      });
    },
    getDefaultCareTeam: function(pcpId) {
      const method = "get";
      const path = document.config.defaultCareTeam + pcpId;
      const payload = null;

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          this.defaultCareTeam = result.data;
          this.getAvailableCareTeamMembers(pcpId);
        })
        .catch(() => {
          this.errors.push("There was a problem trying to get the default care team list");
        });
    },
    isDefaultCareTeamMember: function(proId) {
      if (this.defaultCareTeam.length > 0) {
        return (
          this.defaultCareTeam.filter(function(professional) {
            return professional.id == proId;
          }).length > 0
        );
      }
      return false;
    },
    addPatient: function() {
      this.addPatientLoading = true;
      const method = "put";
      const path = document.config.newPatient;
      const payload = { ...this.patientDataModel, ...this.dataModel };

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.addPatientLoading = false;
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Patient was successfully added.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.closeModal();
          EventBus.$emit("reloadRosterView");
        })
        .catch(error => {
          this.errors.push(error.response.data.message);
          this.addPatientLoading = false;
        });
    },
    checkForm: function() {
      //reset errors
      this.errors = [];

      switch (this.stepNumber) {
        case 1:
          if (!this.patientDataModel.firstName) {
            this.errors.push("First Name is required");
          }
          if (!this.patientDataModel.lastName) {
            this.errors.push("Last Name is required");
          }
          if (!this.patientDataModel.mrn) {
            this.errors.push("ID is required");
          }
          if (!this.patientDataModel.dob) {
            this.errors.push("Birth Date is required");
          } else if (this.patientDataModel.dob.getFullYear() < new Date().getFullYear() - 150 || this.patientDataModel.dob.getFullYear() > new Date().getFullYear()) {
            this.errors.push("Birth Date is invalid");
          }
          if (!this.errors.length) {
            this.nextStep();
          }
          break;
        case 2:
          if (!this.dataModel.assignedProfessionalId) {
            this.errors.push("Please select a Primary Care Provider");
          }
          if (!this.errors.length) {
            this.nextStep();
          }
          break;
        case 3:
          if (this.dataModel.smsPhoneNumber) {
            if (!isValidPhoneNumber(this.dataModel.smsPhoneNumber)) {
              this.errors.push("Please enter a valid 10 digit phone number including area code");
            } else {
              this.dataModel.smsPhoneNumber = formatPhoneNumber(this.dataModel.smsPhoneNumber);
            }
          }
          if (!this.dataModel.gender) {
            this.errors.push("Please select a Gender for this patient");
          }
          if (!this.errors.length) {
            this.addPatient();
          }
          break;
        default:
      }
    }
  },
  computed: {
    pcpsOnly: function() {
      return this.professionals.filter(function(member) {
        return member.pcp;
      });
    }
  },
  mounted: function() {
    const slides = this.$refs.slider;
    this.$nextTick(() => {
      slides.options = this.swiperOption;
    });
    this.getProfessionals();
  }
};
</script>

<style scoped>
.prime-modal-header-steps-container {
  font-weight: 500;
  font-size: 12px;
  color: var(--ion-color-medium-shade);
}
.prime-modal-header-steps-container span {
  margin-right: 10px;
}
.prime-modal-header-steps-pager {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--ion-color-light-shade);
  border-radius: 8px;
  display: inline-block;
  margin-left: 10px;
}
.prime-modal-header-steps-pager.active {
  background: var(--ion-color-primary-tint);
}
.prime-add-patient-pages {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}
.prime-add-patient-pages .swiper-slide {
  flex-direction: column;
  align-items: start;
}
.prime-add-patient-pages .prime-add-patient-slide-content {
  text-align: left;
  width: 100%;
  padding: 0 20px 20px;
}
.prime-add-patient-pages .prime-add-patient-slide-content h6 {
  margin-bottom: 5px;
}
.prime-add-patient-pages .prime-add-patient-slide-content input {
  margin-bottom: 0;
}
.prime-add-patient-pages .care-team-section {
  background: var(--ion-modal-secondary-background);
  border-top: 2px solid var(--ion-color-light-shade);
  /* min-height: 328px; */
  padding-top: 20px;
}
.swiper-slide {
  visibility: hidden;
}

.swiper-slide.swiper-slide-active {
  visibility: visible;
}
.prime-add-patient-pages .care-team-section::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: 0;
  height: 600px;
  background: var(--ion-modal-secondary-background);
}

ion-card.prime-professional-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-professional-card .checkbox {
  margin-left: 5px;
}
ion-card.prime-professional-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
ion-card.prime-professional-card .prime-professional-card-name ion-card.prime-professional-default-card .prime-professional-card-name {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-card .prime-professional-card-info ion-card.prime-professional-default-card .prime-professional-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-default-card {
  background: var(--ion-modal-secondary-background);
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-default-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
.prime-form-block-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}
.prime-form-block-container .prime-form-block-title-row {
  display: flex;
  flex-direction: row;
}
.prime-form-block-container .prime-form-block-title {
  flex-grow: 1;
}
.prime-form-block-container .prime-form-block-title h6 {
  margin: 12px 0;
}
.prime-form-block-container .prime-form-block-input {
  display: flex;
  align-items: center;
}
.prime-form-block-container .prime-form-block-input span {
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}
.prime-form-block-container .prime-form-block-description {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--ion-color-medium-shade);
  margin-top: 2px;
}

ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}

.errors {
  color: var(--ion-color-danger);
  font-weight: 500;
  background: var(--ion-color-danger-contrast);
  padding: 15px 20px;
  display: flex;
  text-align: left;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
.errors svg {
  fill: var(--ion-color-danger);
  width: 32px;
  margin-right: 10px;
}
.add-patient-select {
  width: 100%;
  text-align: left;
  background: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 8px 15px 7px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  color: var(--ion-text-color);
  line-height: 1.5;
  margin-bottom: 15px;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../assets/arrow-dropdown.svg) no-repeat right #fff;
  background-size: 15px;
  background-position-x: 365px;
}

label.unchecked:before {
  background: none !important;
}

label.checked:before {
  background: var(--ion-color-primary) !important;
}

label.checked::after {
  content: "" !important;
}
</style>
