<template>
  <div class="chips-container text-blue pill-mm">
    <div v-for="(chip, index) in chips" :key="index" class="chip text-blue pill-patient">

      <div v-if="chip.filterType === 'set'"
        v-tooltip.bottom="{boundariesElement : 'window', content: tooltipContent(chip), classes: ['custom-tooltip'] }">
        <span v-if="chip.values.length > 1">{{ `${chip.columnName}: ${chip.values.length} selected` }}</span>
        <span v-else>
          {{ `${chip.columnName}: ${chip.values}` }}
        </span>
      </div>
      <div v-else-if="chip.filterType === 'date'">
        <span v-if="chip.type === 'inRange'">
          {{ `${chip.columnName} ${chip.condition} ${formattedDate(chip.dateFrom)} - ${formattedDate(chip.dateTo)}` }}
        </span>
        <span v-else>
          {{ `${chip.columnName} ${chip.condition} ${formattedDate(chip.dateFrom)}` }}
        </span>
      </div>
      <div v-else-if="chip.filterType === 'advanced'">

        <div v-if="chip.key === 'quality_considerations'">
          <span
            v-tooltip.bottom="{boundariesElement : 'window', content: tooltipContentForAdvanceFilterQC(chip.fields), classes: ['custom-tooltip'] }">
            {{ `Adv. Filter: Qual. Cons.: ${totalLength(chip.fields)} sel.` }}
          </span>
        </div>


        <div v-else-if="chip.key === 'diagnosis_considerations'"
          v-tooltip.bottom="{boundariesElement : 'window', content: tooltipContentForAdvanceFilterDC(chip.fields), classes: ['custom-tooltip'] }">
          <span>
            {{ `Adv. Filter: Diag. Cons.: ${totalLength(chip.fields)} sel.` }}
          </span>
        </div>
        <div v-else
          v-tooltip.bottom="{boundariesElement : 'window', content: tooltipContentForAdvanceFilterRC(chip.fields), classes: ['custom-tooltip'] }">
          <span>
            {{ `Adv. Filter: Repo. Cond.: ${totalLength(chip.fields)} sel.` }}
          </span>
        </div>

      </div>
      <div v-else>
        <span>{{ `${chip.columnName} ${chip.condition} ${chip.filter !== undefined && chip.filter !== null ? chip.filter :
          ''}`
        }}</span>
      </div>
      <div style="opacity: 0.5; margin-right: -4px;" @click="removeChip(chip.key)">
        <ion-icon name="md-close-circle" size="small"></ion-icon>
      </div>
    </div>
  </div>
</template>
  
<script>

import moment from "moment";

export default {
  props: {
    chipsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chips: [],
    };
  },
  created() {
    // Initialize chips with the provided data
    this.chips = this.chipsData.map((chip) => ({ ...chip }));
  },
  watch: {
    chipsData: {
      handler(newChips) {
        this.chips = newChips.map((chip) => ({ ...chip }));
      },
      immediate: true,
    },
  },
  methods: {
    getStatusReplacement(inputString) {
      const mapping = {
        "Addressed": 'Marked "Addressed"',
        "Not Addressed" : 'Marked "Not Addressed"',
        "Not Specified": 'Not Marked'
      };

      return inputString.replace(/Addressed|Not Addressed|Not Specified/g, match => {
        return mapping.hasOwnProperty(match)? mapping[match]: match;
      })
    },
    totalLength(data) {
      let total = 0;
      for (const key in data) {
        total += data[key].length;
      }
      return total;
    },
    formatUnderScoreValues(formattedValue) {
      return formattedValue.split(',')
        .map(part => part.trim().split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))
        .join(', ')
    },
    formattedDate(date) {
      if (date === null) {
        return "";
      }
      const momentDate = moment(new Date(date));
      if (momentDate.isValid()) {
        return momentDate.format("MM/DD/YYYY");
      }
    },
    tooltipContent(chip) {
      // Calculate and return tooltip content
      if (chip.values.length > 25) {
        const truncatedValues = chip.values.slice(0, 25).join(', ');
        return `${truncatedValues} and ${chip.values.length - 25} more`;
      } else {
        return chip.values.join(', ');
      }

    },
    tooltipContentForAdvanceFilterQC(data) {
      return Object.keys(data).map(key => {
        if (Array.isArray(data[key]) && data[key].length > 0) {
          let formattedValue = data[key].join(', ');
          let label = '';
          if (key === 's') {
            label = 'By Sub Category';
          } else if (key === 'i') {
            label = 'By Contract Measure';
            formattedValue = formattedValue === '1' ? 'Yes' : 'No';
          } else if (key === 'e') {
            label = 'By CDE Status';
            formattedValue = this.formatUnderScoreValues(formattedValue);
          } else if (key === 'm') {
            label = 'By Measure Name';
          } else if (key === 't') {
            label = 'By Intervention Timing';
            if (formattedValue === "1.0, 2.0, 3.0"
              || formattedValue === "1.0, 3.0, 2.0"
              || formattedValue === "2.0, 1.0, 3.0"
              || formattedValue === "2.0, 3.0, 1.0"
              || formattedValue === "3.0, 2.0, 1.0"
              || formattedValue === "3.0, 1.0, 2.0") {
              formattedValue = "Overdue/Urgent, Due in 3 Months, Due in 6 Months"
            } else if (formattedValue === '1.0, 2.0' || formattedValue === '2.0, 1.0') {
              formattedValue = "Overdue/Urgent, Due in 3 Months"
            } else if (formattedValue === '1.0, 3.0' || formattedValue === '3.0, 1.0') {
              formattedValue = "Overdue/Urgent, Due in 6 Months"
            } else if (formattedValue === '3.0, 2.0' || formattedValue === '2.0, 3.0') {
              formattedValue = "Due in 3 Months, Due in 6 Months"
            }
            else if (formattedValue === '1.0') {
              formattedValue = "Overdue/Urgent"
            } else if (formattedValue === '2.0') {
              formattedValue = "Due in 3 Months"
            } else if (formattedValue === '3.0') {
              formattedValue = "Due in 6 Months"
            }
          } else if (key === 'g') {
            label = 'By Addressed / Not Addressed';
            formattedValue = this.formatUnderScoreValues(formattedValue);
            formattedValue = this.getStatusReplacement(formattedValue);
          }
          return `${label} = ${formattedValue}`;
        } else {
          return '';
        }
      }).filter(Boolean).join('; ');

    },
    tooltipContentForAdvanceFilterDC(data) {
      return Object.keys(data).map(key => {
        if (Array.isArray(data[key]) && data[key].length > 0) {
          let formattedValue = data[key].join(', ');
          let label = '';
          if (key === 's') {
            label = 'Sub Category';
          } else if (key === 'a') {
            label = 'By PCV Attestation Status';
            formattedValue = this.formatUnderScoreValues(formattedValue);
          } else if (key === 'g') {
            label = 'By Addressed / Not Addressed';
            formattedValue = this.formatUnderScoreValues(formattedValue);
            formattedValue = this.getStatusReplacement(formattedValue);
          }
          return `${label} = ${formattedValue}`;
        } else {
          return '';
        }
      }).filter(Boolean).join('; ');

    },
    tooltipContentForAdvanceFilterRC(data) {
      return Object.keys(data).map(key => {
        if (Array.isArray(data[key]) && data[key].length > 0) {
          let formattedValue = data[key].join(', ');
          let label = '';
          if (key === 's') {
            label = 'By Reported Condition';
          } else if (key === 'ay') {
            label = 'By Addressed This Year';
            let addressedThisYear = formattedValue.includes('Yes') && formattedValue.includes('No');
            let notAddressedThisYear = formattedValue === 'No';
            if (addressedThisYear && notAddressedThisYear) {
              formattedValue = 'Addressed This Year, Not Addressed This Year';
            } else if (notAddressedThisYear) {
              formattedValue = 'Not Addressed This Year';
            } else if (formattedValue.includes('Yes') && formattedValue.includes('No')) {
              formattedValue = 'Addressed This Year, Not Addressed This Year';
            } else if (formattedValue === 'Yes') {
              formattedValue = 'Addressed This Year';
            } else if (formattedValue === 'No') {
              formattedValue = 'Not Addressed This Year';
            } else if (formattedValue.includes('No') && formattedValue.includes('Yes')) {
              formattedValue = 'Not Addressed This Year, Addressed This Year';
            }
          } else if (key === 'c') {
            label = 'By Condition Category';
          }
          return `${label} = ${formattedValue}`;
        } else {
          return '';
        }
      }).filter(Boolean).join('; ');
    },
    removeChip(key) {
      const index = this.chips.findIndex((chip) => chip.key === key);
      if (index !== -1) {
        this.chips.splice(index, 1);
        this.$emit("chip-removed", key);
      }
    },
  },
};
</script>
  
<style scoped>
.chips-container {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  margin-top: -6px;
  max-height: 80px;
}

.chip {
  margin: 5px;
  padding: 5px 5px 5px 10px;
  height: 26px;
  font-family: var(--ion-font-family);
  background-color: var(--color-secondary-button);
  display: inline-flex;
  font-weight: 700;
  align-items: center;
  font-size: 12px;
}

.chip span {
  margin-right: 5px;
}

</style>