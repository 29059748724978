<template>
  <div v-if="!isGrouped" class="prime-roster-options-button-container">
    <button @click="showActions($event)" class="prime-button button-clear">
      <ion-icon name="md-more"></ion-icon>
    </button>
  </div>
</template>

<script>
import PopoverRosterPatientOptions from "@/components/Roster/PopoverRosterPatientOptions.vue";

export default {
  name: "ActionRenderer",
  computed: {
    isGrouped() {
      return !this.params.data.hasOwnProperty("accountid");
    }
  },
  methods: {
    showActions(ev) {
      const rowData = this.params.data;
      this.$ionic.popoverController
        .create({
          component: PopoverRosterPatientOptions,
          event: ev,
          mode: "ios",
          cssClass: "popover-width-sm",
          componentProps: {
            data: { beneficiaryId: rowData.beneficiaryid, accountId: rowData.accountid },
            propsData: { rowData }
          }
        })
        .then(p => p.present());
    }
  }
};
</script>

<style scoped>
.prime-roster-options-button-container button.prime-button.button-clear {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 18px;
}
.prime-roster-options-button-container button ion-icon {
  margin-right: 0;
}
</style>
