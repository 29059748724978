<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <h5 slot="start">Patient Enrollment Status</h5>
        <ion-button slot="end" mode="ios" @click="closeModal" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ErrorNotification :errors="errors" />
      <div class="prime-notification-panel">
        <p>{{ formattedEnrollmentStatus }}</p>
        <hr />
        <p>Reason: {{ formattedEnrollmentNote }}</p>
        <p>Date: {{ formattedEnrollmentLastModified }}</p>
      </div>
      <p>{{ formattedStatusCopy }}</p>
    </ion-content>
    <ion-footer>
      <button v-for="(transition, key) in transitions" @click="transition.clickFunction" :key="key" class="prime-button button-block margin-bottom-ten" :class="{ 'button-pending': isLoading }">
        <span>{{ transition.text }}</span>
        <ion-spinner name="dots" duration="1200" />
      </button>
      <button class="prime-button button-block button-secondary" @click="closeModal">
        Cancel
      </button>
    </ion-footer>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import ErrorNotification from "@/components/Global/ErrorNotification";
import EnrollmentInitiatializeForm from "@/components/Tools/EnrollmentTool/EnrollmentInitiatializeForm";
import UnenrollForm from "@/components/Tools/EnrollmentTool/UnenrollForm";
import router from "@/router";

export default {
  name: "EnrollmentDetails",
  components: {
    ErrorNotification
  },
  props: {
    enrollment: Object
  },
  data() {
    return {
      isLoading: false,
      transitions: [],
      errors: [],
      copy: this.$languagePack.enrollment.enrollmentDetails
    };
  },
  computed: {
    formattedEnrollmentStatus() {
      const status = this.enrollment && this.enrollment.status && this.copy.status[this.enrollment.status] ? this.copy.status[this.enrollment.status].title : "Not Enrolled";
      return status;
    },
    formattedEnrollmentLastModified() {
      const note = this.enrollment && this.enrollment.modifiedAt ? this.formatDate(new Date(this.enrollment.modifiedAt)) : "N/A";
      return note;
    },
    formattedEnrollmentNote() {
      let note;
      if (this.enrollment && this.enrollment.note) {
        note = this.enrollment.note;
      } else if (this.copy.status[this.enrollment.status]) {
        note = this.copy.status[this.enrollment.status].reason;
      } else {
        note = "N/A";
      }

      return note;
    },
    formattedStatusCopy() {
      const status = this.enrollment && this.enrollment.status && this.copy.status[this.enrollment.status] ? this.copy.status[this.enrollment.status].copy : "";
      return status;
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    initialize() {
      switch (this.enrollment.status) {
        case "approved":
          this.addUnEnrollButtonToTransitions();
          break;
        case "patientDeclined":
        case "initiated":
        case "sent":
        case "denied":
        case "unenrolled":
          break;
        case "waitingForApproval":
          this.addApprovalButtonToTransitions();
          break;
        case "waitingForPhoneCall":
          this.addApprovalButtonToTransitions("Enrollment Call Requested");
          break;
        case "cancelled":
        case "optout":
          break;
        default:
          this.addDeclineButtonToTransitions();
          break;
      }
    },
    addDeclineButtonToTransitions() {
      const clickFunction = async () => {
        this.isLoading = true;
        const method = "put";
        const path = document.config.enrollmentDecline + this.enrollment.beneficiaryId;

        await httpSend({ path, method, authToken: true });
        this.isLoading = false;
        this.$ionic.toastController
          .create({
            header: "Patient Declined",
            message: "Patient has been declined",
            duration: 4000,
            position: "top",
            color: "primary",
            mode: "ios"
          })
          .then(m => m.present());
        this.closeModal();
      };
      const text = "Mark as Declined";

      this.transitions.push({ clickFunction, text });
    },
    addApprovalButtonToTransitions(text = "Review For Approval") {
      const clickFunction = () => {
        this.closeModal();
        router.push({
          path: "/enrollment/approve?token=" + this.enrollment.id
        });
      };

      this.transitions.push({ clickFunction, text });
    },
    addEnrollButtonToTransitions(text = "Enroll") {
      const data = {
        data: {
          sms: this.enrollment.attributes ? this.enrollment.attributes.smsPhoneNumber : "",
          beneficiaryId: this.enrollment.beneficiaryId
        }
      };
      const clickFunction = () => {
        this.openNewModal(data, EnrollmentInitiatializeForm);
      };

      this.transitions.push({ clickFunction, text });
    },
    addUnEnrollButtonToTransitions() {
      const data = {
        propsData: { enrollment: this.enrollment },
        data: {
          sms: this.smsNumber,
          beneficiaryId: this.beneficiaryId
        }
      };
      const clickFunction = () => {
        this.openNewModal(data, UnenrollForm);
      };
      const text = "Unenroll";

      this.transitions.push({ clickFunction, text });
    },
    async openNewModal(data = {}, component) {
      this.closeModal();
      const modalProperties = {
        mode: "ios",
        component,
        componentProps: {
          ...data
        }
      };
      const modal = await this.$ionic.modalController.create(modalProperties);
      return modal.present();
    },
    formatDate(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      const strTime = hours + ":" + minutes + " " + ampm;
      return date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
    }
  }
};
</script>

<style scoped>
.prime-notification-panel {
  background: var(--color-secondary-button);
  border-radius: 10px;
  padding: 20px;
  margin: 0 0 20px 0;
  font-weight: 400;
}
ion-footer {
  padding: 20px;
}
</style>
