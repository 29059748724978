<template>
  <EnrollmentTool
    v-if="!isGrouped"
    :smsNumber="params.data.sms"
    :enrollmentId="params.data.enrollmentid"
    :enrollmentStatus="params.data.enrollmentstatus || 'notEnrolled'"
    :beneficiaryId="params.data.beneficiaryid"
    :beneficiaryLastName="params.data.benelastname"
  />
</template>

<script>
import EnrollmentTool from "@/components/Tools/EnrollmentTool/EnrollmentTool";

export default {
  name: "EnrollmentStatusRenderer",
  components: {
    EnrollmentTool
  },
  computed: {
    isGrouped() {
      return !this.params.data.hasOwnProperty("accountid");
    }
  }
};
</script>

<style scoped></style>
