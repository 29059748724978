<template>
  <div>
    <ion-row class="bottom-divider">
      <ion-col>
        <h6 class="text-bold pad-left-twenty pad-top-ten pad-bottom-ten">Roster Configuration Presets</h6>
      </ion-col>
    </ion-row>
    <ul class="prime-list lines-top margin-left-twenty">
      <li>
        <ion-row class="ion-align-items-center cursor-pointer" @click="selectPreset({ name: '', 'roster.filterModel': {}, 'roster.columnState': [] })">
          <ion-col size="auto">
            <div class="prime-checkbox margin-right-ten" :class="{ checked: !currentPreset.name }">
              <div />
            </div>
          </ion-col>
          <ion-col>
            <ion-label>
              <span class="text-semibold">Default</span>
            </ion-label>
          </ion-col>
        </ion-row>
      </li>

      <li v-for="preset in presets" :key="preset.name">
        <ion-row class="ion-align-items-center cursor-pointer">
          <ion-col size="auto" @click="selectPreset(preset)">
            <div v-if="currentPreset.name == preset.name" class="prime-checkbox margin-right-ten" :class="changeMode ? 'change' : 'checked'">
              <div />
            </div>
            <div v-else class="prime-checkbox margin-right-ten ">
              <div />
            </div>

            <!-- <div class="prime-checkbox margin-right-ten" :class="{ checked: currentPreset.name == preset.name }">
              <div />
            </div> -->
          </ion-col>
          <ion-col @click="selectPreset(preset)">
            <ion-label>
              <span class="text-semibold">{{ preset.name }}</span>
            </ion-label>
          </ion-col>
          <ion-col size="auto">
            <div class="close-button cursor-pointer" @click="confirmDeletePreset(preset)">
              &times;
            </div>
          </ion-col>
        </ion-row>
      </li>
    </ul>
    <ion-row class="top-divider pad-fifteen">
      <ion-col>
        <button @click="confirmSavePreset" class="prime-button button-block button-primary">Save Column/Filters as Preset</button>
      </ion-col>
    </ion-row>
  </div>
</template>

<script>
import { EventBus } from "@/services/Events.js";
import PopoverSavePreset from "@/components/Roster/PopoverSavePreset";
import { send as httpSend } from "@/services/Api";
import { isEqual } from "lodash";

export default {
  name: "PopoverRosterPresets",
  data() {
    return {
      presetState: {
        test: 1
      },
      currentPreset: { name: "" },
      presets: undefined,
      changeMode: false
    };
  },
  mounted() {
    this.currentPreset.name = this.presetName;
    this.getPresets();
    EventBus.$on("presetUpdated", prstName => {
      this.changeMode = false;
      this.currentPreset.name = prstName;
      this.presetName = prstName;
    });
  },
  methods: {
    getPresets() {
      this.isLoading = true;
      const method = "get";
      const path = document.config.professional + this.professionalId + "/roster/preset";

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.presets = response.data;
          const cPreset = this.presets.find(item => item.name === this.presetName) || "";
          if (cPreset) {
            // compare presets
            const currentStorageFilterModel = window.localStorage.getItem("roster.filterModel");
            const currentStorageColumnState = window.localStorage.getItem("roster.columnState");
            if (!isEqual(cPreset["roster.filterModel"], JSON.parse(currentStorageFilterModel)) || !isEqual(cPreset["roster.columnState"], JSON.parse(currentStorageColumnState))) {
              this.changeMode = true;
            }
          }
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Failed to load Presets",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    selectPreset(preset) {
      this.changeMode = false;
      this.currentPreset = preset;
      window.localStorage.setItem("currentPresetName", preset.name);
      window.localStorage.setItem("roster.filterModel", JSON.stringify(preset["roster.filterModel"]));
      window.localStorage.setItem("roster.columnState", JSON.stringify(preset["roster.columnState"]));
      EventBus.$emit("presetUpdated", preset.name);
    },
    confirmSavePreset() {
      this.$ionic.popoverController
        .create({
          component: PopoverSavePreset,
          mode: "ios",
          cssClass: "popover-width-lg",
          componentProps: {
            data: {
              presetState: this.presetState,
              proId: this.professionalId
            },
            propsData: {
              allPresets: this.presets.map(prst => prst.name)
            }
          }
        })
        .then(p => p.present());

      this.closeModal();
    },
    confirmDeletePreset(preset) {
      this.$ionic.alertController
        .create({
          header: "Confirm Delete",
          message: "Are you sure you want to delete this preset?",
          mode: "ios",
          buttons: [
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Yes",
              handler: () => {
                this.deletePreset(preset);
              }
            }
          ]
        })
        .then(a => a.present());

      this.closeModal();
    },
    deletePreset(preset) {
      const method = "delete";
      const path = document.config.professional + this.professionalId + "/roster/preset/" + preset.name;

      httpSend({ path, method, authToken: true })
        .then(() => {
          if (preset.name === this.currentPreset.name) {
            window.localStorage.removeItem("roster.columnState");
            window.localStorage.removeItem("roster.filterModel");
            EventBus.$emit("presetUpdated", "");
          }
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Failed to load Presets",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },
    closeModal() {
      this.$ionic.popoverController.dismiss({ description: null });
    }
  }
};
</script>

<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}

.prime-checkbox {
  height: 24px;
  width: 24px;
  display: block;
  position: relative;
}
.prime-checkbox div {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  cursor: pointer;
}
.prime-checkbox div::before,
.prime-checkbox div::after {
  content: "";
  display: block;
  transition: all 0.2s ease-in-out;
}
.prime-checkbox div::before {
  height: 20px;
  width: 20px;
  border: 2px solid var(--ion-color-medium-shade);
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.prime-checkbox div:hover::before {
  border: 2px solid var(--ion-color-primary);
}
.prime-checkbox div::after {
  height: 5px;
  width: 10px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  position: absolute;
  top: 7px;
  left: 6px;
}
.prime-checkbox.checked div:before {
  background: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
}

.prime-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
  max-height: 400px;
  overflow-y: scroll;
}
.prime-list li {
  padding: 8px 0;
}
.prime-list.lines-bottom > li {
  border-bottom: 1px solid #dadada;
}
.prime-list.lines-top > li {
  border-top: 1px solid #dadada;
}
.prime-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.prime-list li:first-child {
  border-top: 0;
  padding-bottom: 15px;
}

.close-button {
  font-weight: 400;
  color: #aaaaaa;
  font-size: 32px;
  margin-right: 10px;
}

.prime-checkbox.change div:before {
  border: 2px solid var(--ion-color-primary);
}
</style>
