<template>
  <div>
    <IconSms v-tooltip.top="'Text Message'" class="icons" :class="{ invisible: !params.data.sms }" />
    <IconPhoneVoice v-tooltip.top="'IVR'" class="icons" :class="{ invisible: !params.data.voice }" />
    <IconEmail class="icons" v-tooltip.top="'Email'" :class="{ invisible: !params.data.email }" />
    <IconPhone class="icons" v-tooltip.top="'Live Phone Call'"
      :class="{ invisible: !params.data.voice && !params.data.sms }" />
  </div>
</template>

<script>
import IconPhone from "@/components/Global/Icons/IconPhone";
import IconPhoneVoice from "@/components/Global/Icons/IconPhoneVoice";
import IconSms from "@/components/Global/Icons/IconSms";
import IconEmail from "@/components/Global/Icons/IconEmail";

export default {
  name: "AvailablesChannelRenderer",
  components: {
    IconPhone,
    IconPhoneVoice,
    IconSms,
    IconEmail
  }
};
</script>

<style scoped>
img {
  margin: 10px;
  width: 30px;
  height: 30px;
}

.icons {
  margin: 10px 2px;
  width: 30px;
  height: 30px;
}

.invisible {
  visibility: hidden;
}
</style>
