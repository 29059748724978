<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar>
        <IconPersonAdd slot="start" class="icon-small" />
        <h5 slot="start">Add Patients</h5>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="primary" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="error" class="notification-well errors">
        <IconWarning />
        <div>
          {{ error }}
        </div>
      </div>
      <div v-else-if="totalFailed">
        <div class="text-bold margin-bottom-twenty margin-top-ten">The following issue(s) were found in the file you uploaded:</div>
        <div v-for="(warning, idx) in warnings" :key="idx">
          <div v-if="warning.errorMessage" class="notification-well warnings">
            <IconWarning />
            <div>
              <div v-if="warning.rowId" class="text-bold margin-bottom-five" v-dompurify-html="'Line ' + warning.rowId"></div>
              <div v-dompurify-html="warning.errorMessage"></div>
            </div>
          </div>
        </div>
      </div>
      <ion-grid v-else>
        <ion-row class="margin-bottom-ten">
          Import one or more new patients by downloading and completing the required fields in the Patients tab of the Excel spreadsheet template below.
        </ion-row>
        <ion-row class="margin-bottom-ten">
          <ion-col>
            <h6 class="text-bold">Step 1: Download template</h6>
          </ion-col>
        </ion-row>
        <ion-row class="margin-bottom-ten">
          <ion-col class="full-width">
            <button class="prime-button button-block button-secondary" @click="downloadExcelTemplate" :class="{ 'button-pending': isDownloading }">
              <ion-spinner name="lines" class="spinner-small"></ion-spinner>
              <span v-if="!isDownloading"><IconPaper class="icon-small" />Download Excel Spreadsheet </span>
            </button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="margin-bottom-ten">
            <h6 class="text-bold">Step 2: Upload completed spreadsheet</h6>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <div v-if="!isUploading" class="text-center drag-and-drop" :class="{ dropping: isDropping }" @drop.prevent="dropHandler($event)" @dragover.prevent="isDropping = true" @dragleave.prevent="isDropping = false" @dragend.prevent="isDropping = false">
              <input type="file" name="file" id="file" ref="file" class="prime-file-upload" @change="fileSelected($event)" />
              <label for="file">
                <figure>
                  <ion-icon name="ios-upload"></ion-icon>
                </figure>
                <h5 v-if="!fileToUpload" class="text-bold">Drop file here to upload</h5>
                <div class="text-bold pad-lr-ten">{{ fileToUpload ? fileToUpload.name : "or click to browse" }}</div>
              </label>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col> </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer v-if="error || warnings">
      <button @click="resetUploadState" class="prime-button button-block">
        <span>Try Again</span>
      </button>
    </ion-footer>
    <ion-footer v-else-if="showUploadBtn">
      <ion-grid>
        <ion-row>
          <ion-col>
            <button @click="checkStatus()" class="prime-button button-block" :class="{ 'button-pending': isUploading }">
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Import</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { cloudUpload } from "ionicons/icons";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";
import IconWarning from "@/components/Global/Icons/IconWarning";
import IconPaper from "@/components/Global/Icons/IconPaper";
import fileSizeInRange from "@/utils/FormHelpers/fileSizeInRange";

addIcons({
  "ios-upload": cloudUpload.ios
});

export default {
  name: "ModalAddPatientsImport",
  components: {
    IconPersonAdd,
    IconWarning,
    IconPaper
  },
  data() {
    return {
      isDownloading: false,
      isUploading: false,
      error: undefined,
      warnings: undefined,
      fileToUpload: undefined,
      showUploadBtn: false,
      isDropping: false,
      totalFailed: undefined
    };
  },
  methods: {
    closeModal: function() {
      this.$ionic.modalController.dismiss({ description: null });
    },
    downloadExcelTemplate: function() {
      this.isDownloading = true;
      const method = "get";
      const path = document.config.downloadExcelTemplate;
      const responseType = "blob";

      httpSend({ path, method, authToken: true, responseType })
        .then(response => {
          this.isDownloading = false;
          // force file download
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "new_patients_import_template.xlsx");
          document.body.appendChild(link);
          link.click();
          // end force file download
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Please check your downloads location for the Excel starter template file",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.error = error.response.data.message;
          this.isDownloading = false;
        });
    },
    checkStatus: function() {
      if (!this.error) {
        this.upload();
      }
    },
    fileSelected: function(evt) {
      if (evt.target.value) {
        if (this.isExcelFile(this.$refs.file.files[0].name)) {
          if (fileSizeInRange(this.$refs.file.files[0])) {
            this.fileToUpload = this.$refs.file.files[0];
            this.error = undefined;
            this.showUploadBtn = true;
            this.isDropping = true;
          } else {
            this.$ionic.toastController
              .create({
                header: "Failed to upload file",
                message: "File size must be greater than 0MB and can not exceed 20MB",
                duration: 7000,
                position: "top"
              })
              .then(m => m.present());
          }
        } else {
          this.error = "This file type is not allowed. Please choose a properly formatted Excel spreadsheet to upload";
          this.showUploadBtn = false;
        }
      }
    },
    isExcelFile(fileName) {
      const fileExtension = fileName
        .toLowerCase()
        .split(".")
        .pop()
        .toString();

      if (fileExtension == "xlsx" || fileExtension == "xls" || fileExtension == "xlsb" || fileExtension == "xlsm") {
        return true;
      } else {
        return false;
      }
    },
    dropHandler(ev) {
      if (ev.dataTransfer.items) {
        for (let i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === "file") {
            var file = ev.dataTransfer.items[i].getAsFile();
            if (this.isExcelFile(file.name)) {
              this.fileToUpload = file;
              this.error = undefined;
              this.showUploadBtn = true;
            } else {
              this.error = "This file type is not allowed. Please choose a properly formatted Excel spreadsheet to upload";
              this.showUploadBtn = false;
            }
          }
        }
      } else {
        for (let i = 0; i < ev.dataTransfer.files.length; i++) {
          this.error = "File failed to attach to data transfer service.";
        }
      }
    },
    upload: function() {
      //grab file
      let payload = new FormData();
      payload.append("file", this.fileToUpload);

      this.isUploading = true;
      const method = "post";
      const path = document.config.uploadExcelTemplate;

      httpSend({ path, method, authToken: true, payload })
        .then(response => {
          this.isUploading = false;
          this.isDropping = false;

          if (response.data.totalFailed > 0 && response.data.failureMessages.length > 0) {
            this.totalFailed = response.data.totalFailed;
            this.warnings = response.data.failureMessages;
            this.isUploading = false;
            this.isDropping = false;
          } else if (!response.data.totalFailed) {
            this.$ionic.toastController
              .create({
                header: "Success",
                message: "Upload complete",
                duration: 4000,
                color: "primary",
                mode: "ios"
              })
              .then(m => m.present());
            this.closeModal();
          }

          EventBus.$emit("reloadRosterView");
        })
        .catch(error => {
          this.error = error;
          this.isDropping = false;
          this.isUploading = false;
        });
    },
    resetUploadState() {
      this.error = undefined;
      this.warnings = undefined;
      this.showUploadBtn = false;
      this.fileToUpload = undefined;
      this.totalFailed = undefined;
    }
  }
};
</script>

<style scoped>
.drag-and-drop {
  border: 2px dashed #dadada;
}
.drag-and-drop.dropping {
  border: 2px dashed var(--ion-color-primary);
  background-color: #f0f0f0;
}
.prime-file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.prime-file-upload + label {
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  /* display: inline-block; */
  /* overflow: hidden; */
  white-space: normal;
  padding: 20px;
}
.prime-file-upload + label figure:hover {
  background-color: var(--ion-color-primary-tint);
}
.prime-file-upload + label {
  cursor: pointer; /* "hand" cursor */
  color: var(--ion-color-primary);
}
.prime-file-upload + label ion-icon {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}
.prime-file-upload + label figure {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--ion-color-primary);
  display: block;
  padding: 20px;
  margin: 0 auto 10px;
}

.prime-modal-header-steps-container {
  font-weight: 500;
  font-size: 12px;
  color: var(--ion-color-medium-shade);
}
.prime-modal-header-steps-container span {
  margin-right: 10px;
}
.prime-modal-header-steps-pager {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--ion-color-light-shade);
  border-radius: 8px;
  display: inline-block;
  margin-left: 10px;
}
.prime-modal-header-steps-pager.active {
  background: var(--ion-color-primary-tint);
}
.prime-add-patient-pages {
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
}
.prime-add-patient-pages .swiper-slide {
  flex-direction: column;
  align-items: start;
}
.prime-add-patient-pages .prime-add-patient-slide-content {
  text-align: left;
  width: 100%;
  padding: 0 20px 20px;
}
.prime-add-patient-pages .prime-add-patient-slide-content h6 {
  margin-bottom: 5px;
}
.prime-add-patient-pages .prime-add-patient-slide-content input {
  margin-bottom: 0;
}
.prime-add-patient-pages .care-team-section {
  background: var(--ion-modal-secondary-background);
  border-top: 2px solid var(--ion-color-light-shade);
  /* min-height: 328px; */
  padding-top: 20px;
}
.swiper-slide {
  visibility: hidden;
}

.swiper-slide.swiper-slide-active {
  visibility: visible;
}
.prime-add-patient-pages .care-team-section::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: 0;
  height: 600px;
  background: var(--ion-modal-secondary-background);
}
ion-card.prime-professional-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-professional-card .checkbox {
  margin-left: 5px;
}
ion-card.prime-professional-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
ion-card.prime-professional-card .prime-professional-card-name ion-card.prime-professional-default-card .prime-professional-card-name {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-card .prime-professional-card-info ion-card.prime-professional-default-card .prime-professional-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-professional-default-card {
  background: var(--ion-modal-secondary-background);
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-professional-default-card .avatar {
  width: 50px;
  height: 50px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
.prime-form-block-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}
.prime-form-block-container .prime-form-block-title-row {
  display: flex;
  flex-direction: row;
}
.prime-form-block-container .prime-form-block-title {
  flex-grow: 1;
}
.prime-form-block-container .prime-form-block-title h6 {
  margin: 12px 0;
}
.prime-form-block-container .prime-form-block-input {
  display: flex;
  align-items: center;
}
.prime-form-block-container .prime-form-block-input span {
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}
.prime-form-block-container .prime-form-block-description {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--ion-color-medium-shade);
  margin-top: 2px;
}

ion-footer {
  padding: 20px;
}
ion-footer ion-col:first-child {
  padding-right: 5px;
}
ion-footer ion-col:not(:first-child) {
  padding-left: 5px;
}

.notification-well svg {
  fill: #fff;
  width: 32px;
  margin-right: 10px;
}
.notification-well {
  color: #000;
  font-weight: 500;
  background: var(--ion-color-warning);
  padding: 10px 15px;
  display: flex;
  text-align: left;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
.errors {
  color: #fff;
  background: var(--ion-color-danger);
}
.warning {
  color: #000;
  background: var(--ion-color-warning-shade);
}

.add-patient-select {
  width: 100%;
  text-align: left;
  background: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 8px 15px 7px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  color: var(--ion-text-color);
  line-height: 1.5;
  margin-bottom: 15px;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(../../assets/arrow-dropdown.svg) no-repeat right #fff;
  background-size: 15px;
  background-position-x: 365px;
}
</style>
