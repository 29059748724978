export default {
  autoGroupColumnDef: {
    minWidth: 220,
    cellRendererParams: {
      innerRenderer: "SimpleTextRenderer"
    }
  },
  columnTypes: {
    listColumn: {
      cellStyle: {
        whiteSpace: "pre",
        lineHeight: "100%"
      },
      valueFormatter(params) {
        const list = params.value;
        if (list) {
          const formattedList = list
            .split(",")
            .map(listItem => listItem.trim())
            .join("\n");
          return formattedList;
        }
      }
    }
  },
  columnDefs: [
    {
      children: [
        {
          field: "benelastname",
          headerName: "Last Name"
        },
        {
          field: "benefirstname",
          headerName: "First Name"
        },
        {
          field: "benemiddlename",
          headerName: "Middle Initial"
        },
        {
          filter: "agDateColumnFilter",
          headerName: "Date of Birth",
          field: "dob",
          valueGetter: "data.dobstr"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Age",
          sortable: false,
          valueGetter: "Math.floor((new Date() - new Date(data.dob).getTime()) / 3.15576e+10)"
        },
        {
          field: "mrn",
          headerName: "ID"
        },
        {
          field: "mbr_id",
          hide: true,
          headerName: "Member ID"
        },
        {
          field: "empi",
          hide: true,
          headerName: "EMPI"
        },
        {
          hide: true,
          headerName: "SMS",
          field: "sms",
          filterParams: {
            filterOptions: ["contains", "equals", "startsWith", "endsWith"]
          }
        },
        {
          field: "voice",
          headerName: "Voice"
        },
        {
          field: "email",
          hide: true,
          headerName: "Email"
        },
        {
          filter: false,
          hide: true,
          headerName: "Available Channels",
          sortable: false,
          cellRenderer: "AvailableChannelsRenderer"
        },
        {
          field: "patient_address",
          hide: true,
          headerName: "Patient Address Line 1&2"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Patient City",
          field: "patient_city"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Patient State",
          field: "patient_state"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Patient Zip",
          field: "patient_zip"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "County",
          field: "mbr_address_county"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Patient Gender",
          field: "gender",
          cellStyle: {
            textTransform: "capitalize"
          },
          filterParams: {
            values: ["female", "male"]
          }
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Line of Business",
          field: "line_of_business"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Payer",
          field: "payer"
        },
        {
          field: "attributed_health_system",
          filter: "agSetColumnFilter",
          headerName: "Attributed System"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Product",
          field: "product"
        },
        {
          filter: "agSetColumnFilter",
          hide: false,
          headerName: "Dual Eligible Indicator",
          field: "dual_eligible_indicator"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Full Payer Name",
          field: "payer_plan_lob",
          enableRowGroup: true
        },
        {
          filter: "agDateColumnFilter",
          hide: true,
          headerName: "Health Plan Effective Date",
          field: "mbr_plan_start_date"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Contract PBP",
          field: "mbr_contract_pbp"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Member Type",
          field: "plan_member_type"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Attributed Provider Group",
          field: "pcp_hierarchy_level_3"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Attributed Provider Subgroup",
          field: "pcp_hierarchy_level_4"
        },
        {
          filter: "agSetColumnFilter",
          hide: false,
          headerName: "Attributed Provider Name",
          field: "pcp_name"
        },
        {
          field: "mbr_pcp_npi",
          hide: true,
          headerName: "Attributed Provider NPI"
        },
        {
          field: "pcp_phone",
          hide: true,
          headerName: "Attributed Provider Phone Number"
        },
        {
          filter: "agSetColumnFilter",
          headerName: "Attributed Provider Practice Name",
          field: "pcp_addr_practice_location"
        },
        {
          field: "current_attributed_provider_address",
          hide: true,
          headerName: "Attributed Provider Address"
        },
        {
          filter: "agDateColumnFilter",
          hide: true,
          headerName: "Attributed Provider Effective Date",
          field: "mbr_pcp_start_date"
        },
        {
          field: "last_visit_date",
          filter: "agDateColumnFilter",
          headerName: "Last Provider Visit Date"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Part C RAF Score",
          field: "part_c_raf_score"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Part D RAF Score",
          field: "part_d_raf_score"
        }
      ],
      headerName: "Patient Details"
    },
    {
      children: [
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Enrollment Status",
          field: "enrollmentstatustext",
          cellRenderer: "EnrollmentStatusRenderer",
          minWidth: 250,
          filterParams: {
            values: ["Enrolled", "Enrollment Call Requested", "Enrollment Cancelled", "Enrollment Denied", "Enrollment Initiated", "Enrollment Link Sent", "Not Enrolled", "Awaiting Approval", "Unenrolled", "Opted out"]
          },
          enableRowGroup: true
        },
        {
          filter: "agDateColumnFilter",
          hide: true,
          headerName: "Last Active",
          field: "lastactiveat",
          cellRenderer: "DurationRenderer"
        },
        {
          filter: "agDateColumnFilter",
          hide: true,
          headerName: "Last Outreach Date",
          field: "last_outreach_date",
          valueFormatter: "value ? new Date(value).toLocaleDateString([], {hour:'numeric', minute: '2-digit'}): ''"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last Outreach Channel",
          field: "last_outreach_channel",
          filterParams: {
            values: ["SMS", "IVR"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last Outreach Program",
          field: "last_outreach_program"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last Outreach Status",
          field: "last_outreach_status",
          filterParams: {
            values: ["Outreached: Engaged", "Outreach: Not yet engaged", "In Queue"]
          },
          enableRowGroup: true
        }
      ],
      headerName: "Patient Outreach"
    },
    {
      children: [
        {
          filter: "agSetColumnFilter",
          headerName: "Risk - Unplanned Admission",
          field: "current_uh_risk_score_category",
          cellRenderer: "RiskScoreRenderer",
          minWidth: 260,
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agNumberColumnFilter",
          headerName: "Outreach Score",
          field: "outreach_score",
          valueFormatter: "value ? Math.floor(value) : ''"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Combined Unplanned Hosp/Readmission Risk",
          field: "cupr_risk_score_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          headerName: "CM Risk Score",
          field: "cm_risk_score_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          headerName: "PCV Risk Score",
          field: "pcv_risk_score_category",
          filterParams: {
            values: ["VERY LOW/NO OPPORTUNITY", "LOW OPPORTUNITY", "MODERATELY LOW OPPORTUNITY", "MODERATE OPPORTUNITY", "MODERATELY HIGH OPPORTUNITY", "HIGH OPPORTUNITY"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Transportation",
          field: "sdoh_transportation_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Communication Support",
          field: "sdoh_communication_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Affordability",
          field: "sdoh_affordability_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Employment Support",
          field: "sdoh_employment_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Insurance Coverage",
          field: "sdoh_insurance_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Caregiver Support",
          field: "sdoh_caregiver_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Geography",
          field: "sdoh_geography_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Housing Support",
          field: "sdoh_housing_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "SDoH: Health Behavior",
          field: "sdoh_health_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Prospective Cost Risk Score",
          field: "prospective_cost_risk_score_category",
          filterParams: {
            values: ["LOW", "MODERATE", "MODERATE HIGH", "HIGH", "VERY HIGH"]
          },
          enableRowGroup: true
        }
      ],
      headerName: "Patient Current Risk Scores"
    },
    {
      children: [
        {
          field: "unmet_quality_considerations_count",
          filter: "agNumberColumnFilter",
          headerName: "Count of Unmet Quality Considerations"
        },
        {
          type: "listColumn",
          autoHeight: true,
          hide: true,
          headerName: "Unmet Quality Considerations",
          field: "unmet_quality_considerations_list",
          wrapText: true
        },
        {
          field: "unmet_dx_insights_count",
          filter: "agNumberColumnFilter",
          headerName: "Count of Unmet Diagnosis Considerations"
        },
        {
          type: "listColumn",
          autoHeight: true,
          hide: true,
          headerName: "Unmet Diagnosis Considerations",
          field: "unmet_diagnosis_considerations_list",
          wrapText: true
        }
      ],
      headerName: "Patient Open Alert Summary"
    },
    {
      children: [
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Total Cost YTD",
          field: "ytd_total_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Health Plan Medical Cost YTD",
          field: "ytd_medical_plan_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Health Plan Rx Cost YTD",
          field: "ytd_rx_plan_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Member Medical Cost YTD",
          field: "ytd_medical_member_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Member Rx Cost YTD",
          field: "ytd_rx_member_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Total Cost R12",
          field: "r12_total_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Health Plan Medical Cost R12",
          field: "r12_medical_plan_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Health Plan Rx Cost R12",
          field: "r12_rx_plan_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Member Medical Cost R12",
          field: "r12_medical_member_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "Member Rx Cost R12",
          field: "r12_rx_member_cost",
          valueFormatter: "value ? parseFloat(value).toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : ''"
        }
      ],
      headerName: "Patient Cost Summary"
    },
    {
      children: [
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "YTD Number of Acute Admits",
          field: "ytd_num_acute_admits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "YTD Number of Specialist Visits",
          field: "ytd_num_specialist_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "YTD Number of Non-Specialist Visits",
          field: "ytd_num_non_specialist_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "YTD Number Accountable Care Visits",
          field: "ytd_num_accountable_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "YTD Number of ED Admissions",
          field: "ytd_num_er_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "YTD Number of Avoidable ED Admissions",
          field: "ytd_num_avoidable_er_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "R12 Number of Acute Admits",
          field: "r12_num_acute_admits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "R12 Number of Specialist Visits",
          field: "r12_num_specialist_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "R12 Number of Non-Specialist Visits",
          field: "r12_num_non_specialist_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "R12 Number of Accountable Care Visits",
          field: "r12_num_accountable_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "R12 Number of ED Admissions",
          field: "r12_num_er_visits"
        },
        {
          filter: "agNumberColumnFilter",
          hide: true,
          headerName: "R12 Number of Avoidable ED Admissions",
          field: "r12_num_avoidable_er_visits"
        }
      ],
      headerName: "Patient Visit Summary"
    },
    {
      children: [
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Deceased Indicator",
          field: "dod"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Hospice Indicator",
          field: "hospice_indicator",
          filterParams: {
            values: ["Y", "N"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Nursing Home Indicator",
          field: "nursing_home_indicator",
          filterParams: {
            values: ["Y", "N"]
          },
          enableRowGroup: true
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Long Term Facility Indicator",
          field: "long_term_care_facility_indicator"
        },
        {
          type: "listColumn",
          autoHeight: true,
          hide: true,
          headerName: "Reported Conditions",
          field: "reported_conditions_list",
          wrapText: true
        }
      ],
      headerName: "Patient Condition Summary"
    },
    {
      children: [
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Type",
          field: "last_adt_type",
          cellRenderer: "EventsRenderer"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Admission Type",
          field: "last_adt_admission_type"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Facility",
          field: "last_adt_facility"
        },
        {
          filter: "agDateColumnFilter",
          hide: true,
          headerName: "Last ADT Event Date",
          field: "last_adt_event_date"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Admitting Provider",
          field: "last_adt_admitting_doctor"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Attending Provider",
          field: "last_adt_attending_doctor"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Consulting Provider",
          field: "last_adt_consulting_doctor"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Dx Codes: Descriptions",
          field: "last_adt_diagnosis_codes"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Discharge Disposition",
          field: "last_adt_discharge_disposition"
        },
        {
          filter: "agSetColumnFilter",
          hide: true,
          headerName: "Last ADT Patient Class",
          field: "last_adt_patient_class"
        },
        {
          field: "last_adt_correlation_id",
          hide: true,
          headerName: "Last ADT Correlation Id"
        }
      ],
      headerName: "Patient ADT Summary"
    },
    {
      filter: "AdvancedFilter",
      hide: true,
      suppressFiltersToolPanel: true,
      field: "quality_considerations",
      suppressColumnsToolPanel: true
    },
    {
      filter: "AdvancedFilter",
      hide: true,
      suppressFiltersToolPanel: true,
      field: "diagnosis_considerations",
      suppressColumnsToolPanel: true
    },
    {
      filter: "AdvancedFilter",
      hide: true,
      suppressFiltersToolPanel: true,
      field: "reported_conditions",
      suppressColumnsToolPanel: true
    },
    {
      pinned: "right",
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      resizable: false,
      cellRenderer: "ActionRenderer",
      minWidth: 50,
      sortable: false,
      cellStyle: {
        padding: "0 6.5px"
      },
      filter: false,
      columnGroupShow: "open",
      flex: 0,
      initialPinned: "right",
      width: 50,
      suppressMovable: true
    }
  ],
  defaultColDef: {
    filter: "agTextColumnFilter",
    autoHeaderHeight: true,
    resizable: true,
    flex: 1,
    minWidth: 150,
    sortable: true,
    wrapHeaderText: true,
    filterParams: {
      suppressAndOrCondition: true
    }
  },
  sideBar: {
    toolPanels: [
      {
        toolPanelParams: {
          suppressPivots: true,
          suppressPivotMode: true,
          suppressValues: true
        },
        iconKey: "columns",
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        toolPanel: "agColumnsToolPanel"
      },
      {
        toolPanelParams: {},
        iconKey: "filter",
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        toolPanel: "agFiltersToolPanel"
      },
      {
        iconKey: "filter",
        id: "advancedFilter",
        labelDefault: "Advanced Filter",
        labelKey: "advancedFilter",
        toolPanel: "AdvancedFilters"
      }
    ]
  }
};
