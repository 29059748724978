<template>
  <span
    class="black-font-color"
    :class="{
      'needs-action': needsAction,
      'all-good': doesNotNeedAction
    }"
    ><slot />
  </span>
</template>

<script>
const waiting = ["waitingForApproval", "waitingForPhoneCall"];
const completed = ["approved"];

export default {
  name: "EnrollmentToolText",
  props: {
    status: String
  },
  computed: {
    needsAction() {
      return waiting.includes(this.status);
    },
    doesNotNeedAction() {
      return completed.includes(this.status);
    }
  }
};
</script>

<style scoped>
.all-good {
  margin-left: 34px;
  position: relative;
}
.all-good::before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background: var(--ion-color-success);
  border-radius: 14px;
  left: -30px;
  top: 0px;
}
.needs-action {
  margin-left: 34px;
  position: relative;
}
.needs-action::before {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  background: var(--ion-color-primary);
  border-radius: 14px;
  left: -30px;
  top: 0px;
}
.black-font-color {
  color: black;
  font-size: 13px;
  font-weight: bold;
}
</style>
